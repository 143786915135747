import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card, Avatar, Button, Typography } from 'antd';


import { useGetPathWaysQuery } from '../../services/pathwaysApi';
import "./index.css";
import { useNavigate } from 'react-router-dom';
const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                dots: false,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};


const Pathways = () => {
    const { Title } = Typography;
    const { data: pathWaysArr, error, isLoading } = useGetPathWaysQuery();
    const navigate = useNavigate();

    return (
        <div style={{ margin: "5rem auto", gap: "1rem" }} className="slider-container">
            <Title level={2} style={{ textAlign: "center" }}>Select a Pathway</Title>
            <Slider {...settings}>
                {pathWaysArr?.length ? (
                    pathWaysArr.map(({ id, pathway_description, pathway_logo, pathway_title }) => (
                        <Card
                            className='card-pathway'
                            key={id}
                        >
                            <div className="avatar-middle">
                                <div className="image-pathway">
                                <img src={pathway_logo} alt="PathwayLogo" />
                                </div>
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <Title className='pathway-title' level={3}>{pathway_title}</Title>
                            </div>
                            <div className='pathway-desc' style={{ textAlign: "center" }}>
                                {pathway_description}
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: "1rem", marginTop: "auto" }}>
                                <Button
                                    className='btn-continue'
                                    type='primary'
                                    onClick={() => navigate(`/pathway/${id}`)}
                                >
                                    {" "}
                                    Continue{" "}
                                </Button>
                                <Button
                                    className='btn-cancel'
                                    type='primary'
                                    onClick={() => navigate(`/my-longevity`)}
                                >
                                    {" "}
                                    Cancel{" "}
                                </Button>
                            </div>
                        </Card>
                    ))
                ) : isLoading ? (
                    <Button type="primary" loading>Loading...</Button>
                ) : (
                    <h1>No Data</h1>
                )}
            </Slider>
        </div>
    )
}

export default Pathways