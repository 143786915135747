import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";

export const healthcheckApi = createApi({
  reducerPath: "healthcheckApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({

    getHealthCheck: builder.query({
      query: (type_of_check) => ({ 
        url: `api/mh/health-check/?type_of_check=${type_of_check}`,
        method: "GET",
        // params,
      }),
    }),

    createHealthCheck: builder.mutation({
      query: ( body ) => ({
        url: "api/mh/health-check/",
        method: "POST",
        body,
      }),
    }),

    updateHealthCheck: builder.mutation({
      query: ( body ) => ({
        url: `api/mh/health-check/`,
        method: "PUT",
        body,
      }),
    }),

  }),
});

export const {
  useGetHealthCheckQuery,
  useCreateHealthCheckMutation,
  useUpdateHealthCheckMutation,
  useLazyGetHealthCheckQuery,
} = healthcheckApi;