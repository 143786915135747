import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";

export const marketplaceApi = createApi({
  reducerPath: "marketplaceApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getPartnersByCategory: builder.query({
      query: (payload) => ({
        url: `api/m/offers`,
        method: "GET",
        params: {
          search: payload?.search,
          gender: payload?.gender,
          country: payload?.country,
          discount: payload?.discount,
          categories: payload?.categories,
          offer_types: payload?.offer_types,
          vendor: payload?.vendor,
          page: payload?.page,
          page_size: payload?.page_size,
          ordering: payload?.ordering,
        },
      }),
    }),
    getCategories: builder.query({
      query: () => `api/m/categories?for_longevity_club=true`,
    }),
    getCategoryById: builder.query({
      query: (id) => `api/m/categories/${id}`,
    }),
    getPartnerById: builder.query({
      query: ({ id, reward_client_code }) => `api/m/offers/${id}?rewards_client_code=${reward_client_code}`,
    }),
    getOffersCountries: builder.query({
      query: (category_slug) =>
        `api/m/offers-countries?category_slug=${category_slug}`,
    }),
    getVendors: builder.query({
      query: () => `api/m/vendors`,
    }),
    getOffers: builder.query({
      query: () => `api/m/offers`,
    }),
    getOfferTypes: builder.query({
      query: () => `api/m/offer-types`,
    }),
    getDiscount: builder.query({
      query: () => `api/m/offers?discount`,
    }),
    getNotification: builder.query({
      query: (reward_client_code) => `api/m/offer-notification?rewards_client_code=${reward_client_code}`,
      keepUnusedDataFor: 1,
    }),
    setZendesk: builder.mutation({
      query: () => ({
        url: "api/zendesk-auth/",
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetPartnersByCategoryQuery,
  useGetCategoriesQuery,
  useGetPartnerByIdQuery,
  useGetOffersCountriesQuery,
  useGetCategoryByIdQuery,
  useLazyGetPartnersByCategoryQuery,
  useGetVendorsQuery,
  useGetOffersQuery,
  useGetDiscountQuery,
  useGetOfferTypesQuery,
  useGetNotificationQuery,
  useSetZendeskMutation,
} = marketplaceApi;
