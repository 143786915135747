import { useGetNftCollectionsQuery } from "../../services/nftCollectionsApi";
import Loader from "../Common/Loader";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { Card, Button, Avatar, Row, Typography } from "antd";
const { Meta } = Card;
const { Paragraph, Title, Text } = Typography;

const NftCollectionPage = () => {
  const { data: data, isLoading } = useGetNftCollectionsQuery();

  if (isLoading) {
    return <Loader />;
  }

  const handleWebsiteLink = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="card-container">
      {data?.nft_list?.data?.map((item) => {
        const brand = item.token;
        // const textBelowName = `${item.to_token.symbol} by ${brand.name}`;
        return (
          <Card key={item.id} className="card" size="small">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "15px",
              }}
            >
              <Avatar size={170} src={brand.metadata.media.url} />
            </div>
            <div className="card-body">
              <Meta title={brand.name} description="Longevity Club" />
              <p>
                <strong>Type:</strong> {brand.nft_type}
              </p>
              <p>
                <strong>Description:</strong> {brand.metadata.description.data}
              </p>
              {brand.metadata.description.data == "" ? <br /> : null}
              {}
              <div className="button-container">
                <Button
                  style={{ width: "auto" }}
                  type="primary"
                  onClick={() =>
                    handleWebsiteLink(brand.metadata.external_link)
                  }
                >
                  Website
                </Button>
              </div>
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default NftCollectionPage;
