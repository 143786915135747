import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";

export const storiesApi = createApi({
  reducerPath: "storiesApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllStories: builder.query({
      query: (options) => {
        const { page, page_size } = options; // Extract the page parameter from options
        return {
          url: `api/mh/stories-feed/`, // Remove the page parameter from URL
          method: "GET",
          params: { page, page_size }, // Pass the page parameter as a query parameter
        };
      },
    }),
    addStory: builder.mutation({
      query: (body) => ({
        url: `api/mh/stories/`,
        method: "POST",
        body,
      }),
    }),
    deleteStory: builder.mutation({
      query: (id) => ({
        url: `api/mh/stories/`,
        method: "DELETE",
        params: { story_id: id },
      }),
    }),
    editStory: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `api/mh/stories/`,
        method: "PUT",
        body: { ...body, story_id: id },
      }),
    }),
    getStoryFromId: builder.query({
      query: (story_id) => ({
        url: `api/mh/stories/`,
        method: "GET",
        params: { story_id: story_id },
      }),
    }),
    addComment: builder.mutation({
      query: (body) => ({
        url: `api/mh/comments/`,
        method: "POST",
        body,
      }),
    }),
    deleteComment: builder.mutation({
      query: (id) => ({
        url: `api/mh/comments/`,
        method: "DELETE",
        params: { comment_id: id },
      }),
    }),
    likeStory: builder.mutation({
      query: (id) => ({
        url: `api/mh/story-like/`,
        method: "POST",
        body: { story_id: id },
      }),
    }),
    dislikeStory: builder.mutation({
      query: (id) => ({
        url: `api/mh/story-like/`,
        method: "DELETE",
        params: { story_id: id },
      }),
    }),
  }),
});

export const {
  useGetAllStoriesQuery,
  useGetStoryFromIdQuery,
  useAddStoryMutation,
  useEditStoryMutation,
  useAddCommentMutation,
  useDeleteCommentMutation,
  useLikeStoryMutation,
  useDislikeStoryMutation,
  useDeleteStoryMutation,
} = storiesApi;
