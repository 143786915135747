import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";

export const transactionsApi = createApi({
  reducerPath: "transactionsApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getUserTransactions: builder.query({
      query: () => `api/user-transaction/`,
      method: "GET",
    }),
  }),
});

export const { useGetUserTransactionsQuery } = transactionsApi;
