import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";


export const feedbacksForm = createApi({
    reducerPath: "feedbacksForm",
    baseQuery: baseQueryWithReauth,
    endpoints: (builder)=>({
        sendFeedback:builder.mutation({
            query: (body)=>({
                url: 'api/feedback/', 
                method: "POST",
                body
            })
        })
    })
})

export const {
    useSendFeedbackMutation
} = feedbacksForm;