import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";

export const nftCollectionsApi = createApi({
  reducerPath: "nftCollectionsApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getNftCollections: builder.query({
      query: () => `api/user-nfts/`,
      method: "GET",
    }),
  }),
});

export const { useGetNftCollectionsQuery } = nftCollectionsApi;
