import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "./index.scss";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 50, color: "#0082bd" }} spin />
);

const Index = () => (
  <div className="loader">
    <Spin indicator={antIcon} className="antd-spinner" />
  </div>
);

export const GlobalLoader = () => (
  <div className="suspense-loader">
    <Spin indicator={antIcon} />
  </div>
);

export default Index;

export function MarketplaceLoader () {
  return (

    <div className="marketplace-loader">
    <Spin indicator={antIcon} />
  </div>
    )
}
