import React, { useCallback, useState } from "react";
import { Button, Spin, notification } from "antd";
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  useClientCodeGenerateMutation,
  useConnectToTerraMutation,
  useDisconnectToTerraWidgetMutation,
  useGetAllTerraDevicesQuery,
} from "../../services/connectdevicesAPI";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { GlobalLoader } from "../../components/Common/Loader";

const AddNewModal = () => {
  const [connectedDevices, setConnectedDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const user = useSelector((state) => state.user.data);
  const client_Code = user?.health_client_code;

  const [generateClientCode, { isLoading: isGenerateCodeLoading }] =
    useClientCodeGenerateMutation();

  const generateClientCodeHandler = useCallback(async () => {
    if (!client_Code) {
      const payload = {
        email: user?.email,
        username: user?.first_name + " " + user?.last_name,
      };
      const response = await generateClientCode({ ...payload });
      setClientCode(response?.data?.client_code);
      sessionStorage.setItem(
        "client_code",
        JSON.stringify(response?.data?.client_code)
      );
    }
  }, [
    client_Code,
    generateClientCode,
    user?.email,
    user?.first_name,
    user?.last_name,
  ]);

  const [clientCode, setClientCode] = useState(client_Code || "");

  useEffect(() => {
    if (!client_Code) {
      generateClientCodeHandler();
    }
  }, [client_Code]);

  const {
    data: terraDevices,
    isLoading: isAPILoading,
    refetch: dataRefetch,
  } = useGetAllTerraDevicesQuery(clientCode);

  const filteredTerraDevices = terraDevices?.filter(
    (data) => data.name !== "Apple" && data.name !== "Samsung"
  );

  const [postTerra, { isLoading: isTerraLoading }] =
    useConnectToTerraMutation();
  const [disconnectWidget, { isLoading: isDisconnectWidgetLoading }] =
    useDisconnectToTerraWidgetMutation();

  const handleConnectDevice = async (device) => {
    setIsLoading(true);

    console.log("Helloe");

    try {
      const response = await postTerra({
        client_code: clientCode,
        resource: device.name,
      });
      if (response.data && response.data.status === "success") {
        // window.open(response.data.auth_url, "_blank");
        window.location.href = response.data.auth_url;
        setConnectedDevices([...connectedDevices, device]);
        await dataRefetch();
      }
    } catch (error) {
      await dataRefetch();
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisconnectDevice = async (device) => {
    setIsLoading(true);
    try {
      setConnectedDevices(
        connectedDevices.filter(
          (connectedDevice) => connectedDevice.name !== device.name
        )
      );
      const response = await disconnectWidget({
        client_code: clientCode,
        terraUserId: device.user_id,
      });
      if (response.data && response.data.status === "success") {
        setConnectedDevices([...connectedDevices, device]);
        await dataRefetch();
        notification.success({
          type: "success",
          message: `Disconnected to ${device.name}.`,
        });
      }
    } catch (error) {
      await dataRefetch();
      notification.error({
        type: "error",
        message: `Failed to disconnect from ${device.name}.`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (isTerraLoading || isLoading || isGenerateCodeLoading || isAPILoading) {
    return <GlobalLoader />;
  }

  console.log(filteredTerraDevices,"filteredTerraDevices");

  return (
    <div className="add-new-device-wrapper">
      <div className="add-new-device-container">
        <div className="add-new-title-wrapper">
          <button
            className="add-new-device-back-btn"
            onClick={() => navigate(-1)}
          >
            <ArrowLeftOutlined className="back-left-btn" />
            Back
          </button>
          <div className="add-new-device-title">Connect My Devices</div>
        </div>
        <div className="device-listing-container">
          {filteredTerraDevices?.map((data) => {
            return (
              <div className="device-listing-wrapper" key={data.name}>
                <div className="device-1">
                  <div className="get-points-logo">
                    <img
                      src={data?.logo}
                      alt="logo"
                      className="add-new-device-logo"
                    />
                  </div>

                  <div className="device-name-text">{data?.name}</div>
                </div>
                <div className="btn-wrapper">
                  {data.name !== "Apple" && data.name !== "Samsung" && (
                    <Button
                      type="primary"
                      className={`connect-new-device-btn ${data?.user_id ? "connect" : "disconnect"
                        }`}
                      disabled={isDisconnectWidgetLoading}
                      onClick={() => {
                        if (data?.user_id) {
                          handleDisconnectDevice(data);
                        } else {
                          handleConnectDevice(data);
                        }
                      }}
                    >
                      {data?.user_id ? "Disconnect" : "Connect"}
                    </Button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AddNewModal;
