import { createApi } from "@reduxjs/toolkit/dist/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";


export const userSegmentsAPI = createApi({
    reducerPath:"userSegments",
    baseQuery:baseQueryWithReauth,
    endpoints:(builder)=>({
        getListSegments: builder.query({
            query: () =>({
                url:"api/list-segments/",
                method:"GET"
            })
        }),
        assignSegment: builder.mutation({
            query: (segmentID) =>({
                url:"api/assign-segment/",
                method:"POST",
                body:{
                    segment_id:segmentID
                }
            })
        }),

    })
})

export const { 
    useGetListSegmentsQuery,
    useAssignSegmentMutation
} = userSegmentsAPI