import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";

export const cancerApi = createApi({
  reducerPath: "cancerApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getCancerData: builder.query({
      query: (type_of_check) => `api/mh/health-check/?type_of_check=${type_of_check}`,
    }),
    setCancerData: builder.mutation({
      query: ( body ) => ({
        url: `api/mh/health-check/`,
        method: "POST",
        body,
        prepareHeaders: (headers) => {
            headers.set("Content-Type", "multipart/form-data");
            return headers;
        },
      }),
    }),
    updateCancerData: builder.mutation({
      query: ( body ) => ({
        url: `api/mh/health-check/`,
        method: "PUT",
        body,
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
      }),
    }),
  }),
});

export const { useGetCancerDataQuery, useSetCancerDataMutation, useUpdateCancerDataMutation } = cancerApi;