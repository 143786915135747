import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";

export const healthApi = createApi({
  reducerPath: "healthApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getMonthSteps: builder.query({
      query: () => ({
        url: `api/mh/activities/month`,
        method: "GET",
      }),
    }),
    getTodaySteps: builder.query({
      query: ({ actualUserClientCode, selectedDate }) => ({
        url: `api/mh/activities/weekly`,
        method: "GET",
        params: { start_date: selectedDate, end_date: selectedDate },
      }),
    }),
    createClientCode: builder.mutation({
      query: (payload) => ({
        url: `api/mh/activity-history`,
        method: "POST",
        body: payload,
      }),
    }),
    getQuestionnaire: builder.query({
      query: () => `api/question/`,
    }),
    getCreateAnswer: builder.query({
      query: () => `api/user-answer/`,
    }),
    createQuestionnaireAnswer: builder.mutation({
      query: (payload) => ({
        url: `api/user-answer/`,
        method: "POST",
        body: payload,
      }),
    }),
    getHealthScore: builder.query({
      query: () => `api/mh/health-score/`,
    }),
    getHealthChart: builder.query({
      query: ({selectedChartOption,actualUserClientCode}) => `api/mh/health-chart/v2/?duration=${selectedChartOption}&health_client_code=${actualUserClientCode}`,
    }),
    getTodayLongevityIndicators: builder.query({
      query: ()=>({
        url: 'api/mh/indicators/',
        method: 'GET'
      })
    })
  }),
});

export const {
  useGetMonthStepsQuery,
  useGetTodayStepsQuery,
  useCreateClientCodeMutation,
  useGetQuestionnaireQuery,
  useGetCreateAnswerQuery,
  useCreateQuestionnaireAnswerMutation,
  useGetHealthScoreQuery,
  useGetHealthChartQuery,
  useGetTodayLongevityIndicatorsQuery
} = healthApi;
