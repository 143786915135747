import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";

export const qibeeRewardsApi = createApi({
  reducerPath: "qibeeRewardsApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getLoyaltyMembers: builder.query({
      query: () => `api/loyalty-members/`,
      method: "GET",
    }),
    exchangePoints: builder.mutation({
      query: (body) => ({
        url: `api/exchange-points/`,
        method: "POST",
        body,
      }),
    }),
    getQiibeeAccountNumber: builder.query({
      query: () => `api/qiibee-account-number/`,
      method: "GET",
    }),
  }),
});

export const {
  useGetLoyaltyMembersQuery,
  useExchangePointsMutation,
  useGetQiibeeAccountNumberQuery,
} = qibeeRewardsApi;
