import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";

export const mindfulnessApi = createApi({
  reducerPath: "mindfulnessApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getPlaylistData: builder.query({
      query: (id) => `api/mh/playlist/?playlist_id=${id}`,
    }),
    audioRewards: builder.mutation({
      query: ( body ) => ({
        url: `api/r/event`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetPlaylistDataQuery ,useAudioRewardsMutation} = mindfulnessApi;
