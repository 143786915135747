import React, { useState } from "react";
import BaseLayout from "../BaseLayout";
import "./index.scss";
import { useGetHealthChartQuery } from "../../../services/healthApi";
import { Radio } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const BarChartComponent = () => {
    const user = useSelector((state) => state.user.data);
    const actualUserClientCode = user?.health_client_code;
    const [selectedChartOption, setSelectedChartOption] = useState("last_one_day");
    const [selectedTag, setSelectedTag] = useState('Day')
    const [selectedSleepTag, setSelectedSleepTag] = useState('Daily')
    const { data: chartData } = useGetHealthChartQuery({ selectedChartOption, actualUserClientCode })

    const navigate = useNavigate()

    const Tags = {
        "last_one_day": 'Day',
        "last_one_week": 'Day',
        "last_one_month": 'Day',
        "last_three_months": "Day",
        "last_six_months": "Day",
        "last_one_year": "Day"
    }
    // const Tags = {
    //     "last_one_day": 'Day',
    //     "last_one_week": 'Week',
    //     "last_one_month": 'Month',
    //     "last_three_months": "3 Months",
    //     "last_six_months": "6 Months",
    //     "last_one_year": "Year"
    // }

    const sleepTimeTag = {
        "last_one_day": 'Daily',
        "last_one_week": 'Daily',
        "last_one_month": 'Daily',
        "last_three_months": "Daily",
        "last_six_months": "Daily",
        "last_one_year": "Daily"
    }
    // const sleepTimeTag = {
    //     "last_one_day": 'Daily',
    //     "last_one_week": 'Weekly',
    //     "last_one_month": 'Monthly',
    //     "last_three_months": "last 3 Months",
    //     "last_six_months": "6 Months",
    //     "last_one_year": "Yearly"
    // }

    const onChange = (e) => {
        setSelectedChartOption(e.target.value)
        setSelectedTag(Tags[e.target.value]);
        setSelectedSleepTag(sleepTimeTag[e.target.value])
    };

    function formatToIntegerWithCommas(number) {
        try{
            let integerPart = Math.floor(number);
            return integerPart.toLocaleString();
        }catch{
            return 0
        }
    }


    const formatTimeInBed = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return (
            <>
                {
                    seconds ?
                        <span>{hours}<span style={{ fontSize: '16px' }}>hr</span> {minutes}<span style={{ fontSize: '16px' }}>min</span></span>
                        :
                        <span>0<span style={{ fontSize: '16px' }}>hr</span> 0<span style={{ fontSize: '16px' }}>min</span></span>
                }
            </>
        )


        // `${hours}hr ${minutes}min`;
    };

    return (
        <>
            <BaseLayout path="/health/insights">
                <div className="health-insight-wrapper">

                    <div className="health-insight-title">
                        Health Insights
                    </div>

                    <Radio.Group className="insight-period-buttons" onChange={onChange} defaultValue="last_one_day">
                        <Radio.Button style={{ flexGrow: '1', textAlign: 'center' }} value="last_one_day">D</Radio.Button>
                        <Radio.Button style={{ flexGrow: '1', textAlign: 'center' }} value="last_one_week">W</Radio.Button>
                        <Radio.Button style={{ flexGrow: '1', textAlign: 'center' }} value="last_one_month">M</Radio.Button>
                        <Radio.Button style={{ flexGrow: '1', textAlign: 'center' }} value="last_three_months">3M</Radio.Button>
                        <Radio.Button style={{ flexGrow: '1', textAlign: 'center' }} value="last_six_months">6M</Radio.Button>
                        <Radio.Button style={{ flexGrow: '1', textAlign: 'center' }} value="last_one_year">Y</Radio.Button>
                    </Radio.Group>

                    <div className="insights-cards-grid-wrapper">
                        <div className="insight-card-main-wrapper" onClick={() => navigate(`/Health/insights/detail/My Steps/${selectedChartOption}/steps`)}>
                            <div className="insight-card-wrapper">
                                <div className="insight-card-title">My Steps</div>
                                <div>
                                    <div className="insight-card-quantity">{chartData?.summary?.avg_steps ? formatToIntegerWithCommas(chartData?.summary?.avg_steps) : 0}</div>
                                    {/* <div className="insight-card-quantity">{chartData?.summary?.avg_steps ? chartData?.summary?.avg_steps?.toLocaleString() : 0}</div> */}
                                    <div className="insight-card-quantity-label">Steps/{selectedTag}</div>
                                </div>
                            </div>
                        </div>
                        <div className="insight-card-main-wrapper" onClick={() => navigate(`/Health/insights/detail/Distance/${selectedChartOption}/distance`)}>
                            <div className="insight-card-wrapper">
                                <div className="insight-card-title">Distance</div>
                                <div>
                                    <div className="insight-card-quantity">{chartData?.summary?.avg_distance_meters ? (chartData?.summary?.avg_distance_meters / 1000)?.toLocaleString() : 0}</div>
                                    <div className="insight-card-quantity-label">km/{selectedTag}</div>
                                </div>
                            </div>
                        </div>
                        <div className="insight-card-main-wrapper" onClick={() => navigate(`/Health/insights/detail/Time in Bed/${selectedChartOption}/total_duration_in_bed_seconds`)}>
                            <div className="insight-card-wrapper">
                                <div className="insight-card-title">Time in Bed</div><div>
                                    <div className="insight-card-quantity">{formatTimeInBed(chartData?.summary?.avg_duration_in_bed_seconds)}</div>
                                    <div className="insight-card-quantity-label">{selectedSleepTag} sleep time</div>
                                </div>
                            </div>
                        </div>
                        <div className="insight-card-main-wrapper" onClick={() => navigate(`/Health/insights/detail/Calories Burned/${selectedChartOption}/total_burned_calories`)}>
                            <div className="insight-card-wrapper">
                                <div className="insight-card-title">Calories Burned</div>
                                <div>
                                    <div className="insight-card-quantity">{chartData?.summary?.avg_burned_calories ? (chartData?.summary?.avg_burned_calories * 1000)?.toLocaleString() : 0}</div>
                                    <div className="insight-card-quantity-label">cal/{selectedTag}</div>
                                </div>
                            </div>
                        </div>
                        <div className="insight-card-main-wrapper" onClick={() => navigate(`/Health/insights/detail/Total Activiy/${selectedChartOption}/activity_seconds`)}>
                            <div className="insight-card-wrapper">
                                <div className="insight-card-title">Total Activiy</div>
                                <div>
                                    <div className="insight-card-quantity">{chartData?.summary?.avg_activity_seconds ? (chartData?.summary?.avg_activity_seconds / 60)?.toFixed(1).toLocaleString() : 0}</div>
                                    <div className="insight-card-quantity-label">minutes/{selectedTag}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                </div>

            </BaseLayout>
        </>
    );
};

export default BarChartComponent;

