import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";

export const connectedDevicesApi = createApi({
  reducerPath: "connectedDevicesApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllConnectedDevices: builder.query({
      query: (client_code) => ({
        url: `api/mh/terra-subscribed-user/${client_code}`,
        method: "GET",
      }),
    }),
    connectToTerraWidget: builder.mutation({
      query: (client_code) => ({
        url: `api/mh/terra-widget/${client_code}`,
        method: "POST",
      }),
    }),
    connectToTerra: builder.mutation({
      query: ({ client_code, ...payload }) => ({
        url: `api/mh/terra-auth-user/${client_code}?resource=${payload.resource}`,
        method: "POST",
      }),
    }),
    getAllTerraDevices: builder.query({
      query: (client_code) => ({
        url: `api/mh/terra-provider/${client_code}`,
        method: "GET",
      }),
    }),
    disconnectToTerraWidget: builder.mutation({
      query: ({ client_code, ...payload }) => ({
        url: `api/mh/terra-deauth-user/${client_code}`,
        method: "POST",
        body: payload,
      }),
    }),
    terraUserData: builder.mutation({
      query: ({ client_code, ...payload }) => ({
        url: `api/mh/terra-user-data/${client_code}`,
        method: "POST",
        body: payload,
      }),
    }),
    clientCodeGenerate: builder.mutation({
      query: (payload) => ({
        url: `api/mh/client-code-generate/`,
        method: "POST",
        body:payload
      }),
    }),
  }),
});

export const {
  useGetAllConnectedDevicesQuery,
  useConnectToTerraWidgetMutation,
  useConnectToTerraMutation,
  useDisconnectToTerraWidgetMutation,
  useTerraUserDataMutation,
  useClientCodeGenerateMutation,
  useGetAllTerraDevicesQuery,
} = connectedDevicesApi;
