import { Modal, message } from "antd";
import SocialMediaInformationForm from "./SocialMediaInformationForm";
import { useCallback, useState } from "react";
import { ACCOUNT_TYPES, removeEmptyData } from "../../utils";
import {
  useAccountTypeMutation,
  useSocialMediaInformationMutation,
} from "../../services/userApi";
import { useDispatch } from "react-redux";
import { updateUser, updateUserCountry } from "../../store/userSlice";
import { useNavigate } from "react-router-dom";

const SocialMediaInformationPopup = ({ open, onFinish, onClose }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [accountType] = useAccountTypeMutation();
  const [socialMediaInformation] = useSocialMediaInformationMutation();

  const onSocialInformationSubmit = useCallback(
    (values) => {
      socialMediaInformation(removeEmptyData(values))
        .then(({ data, error }) => {
          if (error?.data?.error) {
            if (error?.data?.error?.account_type?.length) {
              message.error(error?.data?.error?.account_type?.join("; "));
            } else {
              message.error(
                error?.data?.error || "Something went wrong, Please try again"
              );
            }
          } else {
            message.success(data?.msg);
            dispatch(updateUser(data.token));
            dispatch(updateUserCountry(data.country_code));
            setLoading(false);
            onFinish?.();
            navigate("/ambassador-program");
          }
        })
        .catch(() => {
          message.error("Something went wrong, Please try again");
        });
    },
    [socialMediaInformation, dispatch, onFinish, navigate]
  );

  const onSubmit = useCallback(
    (values) => {
      setLoading(true);
      accountType({ type_of_account: ACCOUNT_TYPES.AMBASSADOR })
        .then(({ data, error }) => {
          if (error?.data?.error) {
            message.error(
              error?.data?.error || "Something went wrong, Please try again"
            );
            setLoading(false);
          } else {
            onSocialInformationSubmit(values);
          }
        })
        .catch(() => {
          message.error("Something went wrong, Please try again");
          setLoading(false);
        });
    },
    [accountType, onSocialInformationSubmit]
  );

  return (
    <Modal
      open={open}
      footer={null}
      title="Join our ambassador program"
      onCancel={onClose}
    >
      <SocialMediaInformationForm
        fromPopup
        onSubmit={onSubmit}
        isLoading={loading}
      />
    </Modal>
  );
};

export default SocialMediaInformationPopup;
