import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";


export const adminTools = createApi({
    reducerPath: "adminTools",
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getDevicesIds: builder.query({
            query: ({ gender,
                country,
                email,
                type_of_account,
                language,
                date_of_birth }) => ({
                    url: `api/push-notification/?user__gender=${gender}&user__country=${country}&user__email=${email}&user__type_of_account=${type_of_account}&user__language=${language}&user__date_of_birth=${date_of_birth}`,
                    method: "GET",
                    // params:params
                })
        }),
        sendNotification: builder.mutation({
            query: (body) => ({
                url: 'api/send-notification/',
                method: 'POST',
                body
            })
        })
    })
})

export const {
    useGetDevicesIdsQuery,
    useSendNotificationMutation
} = adminTools;