import { createSlice } from "@reduxjs/toolkit";
import jwt from "jwt-decode";

const initialState = {
  country_code: "",
  rewardPoints: {
    points: 0,
  },
  accountTypes: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      if (action.payload) {
        state.data = { ...action.payload, ...jwt(action.payload?.access) };
      } else {
        state.data = null;
      }
    },
    updateAccessToken: (state, action) => {
      state.data = {
        ...state.data,
        access: action.payload,
        ...jwt(action.payload),
      };
    },
    updateRewardPoints: (state, action) => {
      state.rewardPoints = action.payload;
    },
    updateAccountType: (state, action) => {
      state.accountTypes = action.payload;
    },
    updateUserCountry: (state, action) => {
      state.country_code = action.payload;
    },
  },
});

export const {
  updateUser,
  updateAccessToken,
  updateRewardPoints,
  updateAccountType,
  updateUserCountry,
} = userSlice.actions;

export default userSlice.reducer;
