
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";
import { updateAccessToken, updateUser } from "../store/userSlice";
import { API_BASE_URL } from "../utils";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: (headers, api) => {
    if(api.getState().user?.data?.access){
      headers.append(
        "Authorization",
        `Bearer ${api.getState().user?.data?.access}`
      );
      return headers;
    }
    else return undefined;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  const userData = api.getState().user?.data;
  let result = await baseQuery(args, api, extraOptions);
  
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          {
            url: "api/token/",
            method: "POST",
            body: { refresh: userData.refresh },
          },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          api.dispatch(updateAccessToken(refreshResult.data.access));
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(updateUser(null));
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();

      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export default baseQueryWithReauth;

