import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";

export const rewardApi = createApi({
  reducerPath: "rewardApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getBalance: builder.query({
      query: (clientCode) => `api/r/balance/${clientCode}`,
    }),
    getTransactions: builder.query({
      query: (clientCode) => `api/r/balance-history/${clientCode}`,
    }),
    longevityPoints: builder.query({
      query: ({rewardsClientCode , selectedChartOption }) => `api/r/reward-points-report/${rewardsClientCode}?duration=${selectedChartOption}&by=sum`,
    }),
    getAllPoints: builder.query({
      query: ({rewardsClientCode , selectedChartOption }) => `api/r/reward-points-report/${rewardsClientCode}?duration=${selectedChartOption}`,
    }),
    lastOneMonthInsightsPoints: builder.query({
      query: (rewardClientCode) => `api/r/reward-points-report/${rewardClientCode}?duration=last_one_month&by=date`,
    }),
    getDiscountOffer: builder.query({
      query: ({ clientCode, offerId }) =>
        `api/r/balance/${clientCode}/discount-offer/${offerId}`,
    }),
    getMorePoints: builder.query({
      query: () =>
        `api/r/event-type`,
    }),
    purchaseDiscountOffer: builder.mutation({
      query: ({ clientCode, offerId, ...body }) => ({
        url: `api/r/balance/${clientCode}/discount-offer/${offerId}`,
        method: "POST",
        body,
      }),
    }),
    addRewardPoints: builder.mutation({
      query: (body) => ({
        url: `api/r/event`,
        method: "POST",
        body,
      }),
    }),
    getAccountStatus: builder.query({
      query: () => `api/account-type-status/`,
    }),
    rewardReport: builder.query({
      query: ({ startDate, endDate, pagination }) => `api/r/reward-report/?start_date=${startDate}&end_date=${endDate}&page=${pagination?.current}&per_page=${pagination?.pageSize - 1}`,
    }),
  }),
});

export const {
  useGetBalanceQuery,
  useGetTransactionsQuery,
  useGetDiscountOfferQuery,
  usePurchaseDiscountOfferMutation,
  useAddRewardPointsMutation,
  useLazyGetBalanceQuery,
  useGetAccountStatusQuery,
  useGetMorePointsQuery,
  useLongevityPointsQuery,
  useLastOneMonthInsightsPointsQuery,
  useRewardReportQuery,
  useGetAllPointsQuery
} = rewardApi;
