import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";

export const SearchRecipeApi = createApi({
  reducerPath: "SearchRecipeApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({

    getSearchRecipe: builder.query({
      query: (recipe) => ({ 
        url: `/api/mh/search-recipe/${recipe}`,
        method: "GET",
      }),
    }),
    getRecipeNutrient: builder.query({
      query: (recipe_id) => ({ 
        url: `/api/mh/recipe-nutrition/${recipe_id}`,
        method: "GET",
      }),
    }),
    getSearchFood: builder.query({
      query: (food_item) => ({ 
        url: `/api/mh/food-nutrition-info/?food_item=${food_item}`,
        method: "GET",
      }),
    }),

    getFoodNutrition: builder.query({
      query: (food_id) => ({ 
        url: `/api/mh/food-nutrition-by-id/${food_id}`,
        method: "GET",
      }),
    }),

    getFoodDetails: builder.mutation({
      query: (body) => ({ 
        url: `/api/mh/search-recipe-by-food-image/`,
        method: "POST",
        body,
        prepareHeaders: (headers) => {
          headers.set("Content-Type","multipart/form-data");
          return headers
        }
      }),
    }),
  }),
});

export const {
  useGetSearchRecipeQuery,
  useGetRecipeNutrientQuery,
  useGetFoodDetailsMutation,
  useGetSearchFoodQuery,
  useGetFoodNutritionQuery,
} = SearchRecipeApi;