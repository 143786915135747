import {
  LikeFilled,
  LikeOutlined,
  SendOutlined,
  DeleteTwoTone,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./index.scss";
import { Input, notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddCommentMutation,
  useDeleteCommentMutation,
  useDislikeStoryMutation,
  useGetStoryFromIdQuery,
  useLikeStoryMutation,
} from "../../../services/storyApi";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { GlobalLoader } from "../../../components/Common/Loader";
import BaseLayout from "../BaseLayout";
import { ArrowLeftOutlined } from "@ant-design/icons";

const StoryComment = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: storyDetails,
    isLoading,
    refetch,
  } = useGetStoryFromIdQuery(Number(id));
  const [addComment] = useAddCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();
  const [likeStory] = useLikeStoryMutation();
  const [dislikeStory] = useDislikeStoryMutation();

  const [commentText, setCommentText] = useState("");

  useEffect(() => {
    refetch();
  }, [refetch]);

  const loginUserDetails = useSelector((state) => state.user.data);
  const isUserStory = storyDetails?.email === loginUserDetails.email;

  const handleCommentAdd = async () => {
    const apiPayload = {
      story_id: Number(id),
      comment_story: commentText,
    };
    const response = await addComment(apiPayload);
    refetch();
    if (response?.data) {
      setCommentText("");
      notification.success({
        type: "success",
        message: "Comment Added Successfully",
      });
    } else {
      notification.error({
        type: "error",
        message: "Something Went Wrong!",
      });
    }
  };

  if (isLoading) {
    return <GlobalLoader />;
  }

  const handleCommentDelete = async (id) => {
    const response = await deleteComment(id);
    refetch();
    if (response?.data) {
      notification.success({
        type: "success",
        message: "Comment Deleted Successfully",
      });
    }
  };

  const handleCommentAction = async (type) => {
    let response;
    if (type === "like") {
      response = await likeStory(Number(id));
    } else {
      response = await dislikeStory(Number(id));
    }
    if (response?.data) {
      await refetch();
      if (type === "like") {
        notification.success({
          type: "success",
          message: "You have Liked This Story",
        });
      } else {
        notification.success({
          type: "success",
          message: "You have Disliked This Story",
        });
      }
    } else {
      notification.error({
        type: "error",
        message: "Something Went Wrong!",
      });
    }
  };
  const handleStoryNavigate = () => {
    if (id) {
      navigate(`/health/stories`);
    } else {
      navigate("/health/stories");
    }
  };

  return (
    <BaseLayout path="/health/stories">
      <ArrowLeftOutlined className="back-arrow" onClick={handleStoryNavigate} />
      <div className="stories-wrapper-main">
        <div className="main-box-comment">
          <div className="card-header-title">
            <div className="stories-name">{storyDetails?.title}</div>
            <div className="stories-description">
              {dayjs(storyDetails?.date).format("HH:mm, DD MMM YYYY")}
            </div>
          </div>

          <br />
          <div className="card-header-title">
            <div className="stories-username">{storyDetails?.description}</div>
            <div className="likes-section">
              <div className="likes-count">{storyDetails?.likes_count}</div>
              {storyDetails?.liked_by?.some(
                (z) => z?.email === loginUserDetails?.email
              ) ? (
                <LikeFilled
                  style={{ color: "#0064A9", fontSize: "32px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCommentAction("dislike");
                  }}
                />
              ) : (
                <LikeOutlined
                  style={{ color: "#0064A9", fontSize: "32px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCommentAction("like");
                  }}
                />
              )}
              {isUserStory && (
                <div
                  className="edit-story-text"
                  onClick={() =>
                    navigate(`/health/stories/edit/${storyDetails.id}`)
                  }
                >
                  Edit
                </div>
              )}
            </div>
          </div>
          <br />
          <br />
          <br />
          <Input
            placeholder="Your Comments"
            className="comment-input"
            value={commentText}
            onPressEnter={handleCommentAdd}
            onChange={(e) => setCommentText(String(e.target.value))}
            suffix={
              <SendOutlined className="send-icon" onClick={handleCommentAdd} />
            }
          />
        </div>
        <br />
        <br />
        <div className="comments-section">Comments</div>
        <br />
        <br />
        <div className="comments-box">
          {storyDetails?.comments.map((story) => {
            return (
              <>
                <div className="comment-stories-wrapper" key={story.id}>
                  <div className="stories-header">
                    <div className="stories-user-username">
                      {story?.username}
                    </div>
                    <div className="likes-count">
                      {dayjs(story?.date).format("HH:mm, DD MMM YYYY")}
                    </div>
                  </div>
                  <div className="stories-footer">
                    <div className="comment-stories-description">
                      {story?.comment_story}
                    </div>
                    {story?.email === loginUserDetails.email && (
                      <div
                        className="delete-comment"
                        onClick={() => handleCommentDelete(Number(story?.id))}
                      >
                        <DeleteTwoTone />
                      </div>
                    )}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </BaseLayout>
  );
};

export default StoryComment;
