
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";

export const fitnessApi = createApi({
    reducerPath: "fitnessApi",
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getFitnessBaseImage: builder.query({
            query: () => ({
                url: 'api/mh/base-image/',
                method: "GET",
            }),
        }),
        getFitnessBaseImageColor: builder.query({
            query: (muscleGroup) => ({
                url: `api/mh/single-color-muscle-group/`,
                method: "GET",
                params: {
                    muscleGroups: muscleGroup,
                },
            }),
        }),
        getGenerateFinessPlan: builder.query({
            query: (fitnessParams) => ({
                url: `api/mh/workout-plan/`,
                method: "GET",
                params: {
                    time: fitnessParams.decodedDuration,
                    muscle: fitnessParams.decodedFitnessMuscle,
                    // fitness_level: fitnessParams.decodedFitnessLevel,
                    // fitness_goals: fitnessParams.decodedFitnessGoal,
                    location: fitnessParams.decodedFitnessLocation,
                },
            }),
        }),

        getFitnessList: builder.query({
            query: () => ({
                url: `api/mh/fitness-plan-list/`,
                method: "GET",
            }),
        }),

        setFitnessList: builder.mutation({
            query: (body) => ({
                url: `api/mh/fitness-plan-list/`,
                method: "POST",
                body,
            }),
        }),

        deleteFitnessListItem: builder.mutation({
            query: (itemId) => ({
                url: `api/mh/fitness-plan-list/`,
                method: "DELETE",
                params: {
                    id: itemId
                }
            }),
        }),
    }),
    
});


export const {
    useGetFitnessBaseImageQuery,
    useGetFitnessBaseImageColorQuery,
    useGetGenerateFinessPlanQuery,
    useGetFitnessListQuery,
    useSetFitnessListMutation,
    useDeleteFitnessListItemMutation
} = fitnessApi
