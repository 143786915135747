import { message } from "antd";

import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

export const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const PATH_NOT_TO_SHOW_HEADER = [
  "/onboarding",
  "/social-media-information",
];

export const ACCOUNT_TYPES = {
  CORPORATE_CLUB_MEMBER: "CCM",
  CLUB_MEMBER: "CM",
  AMBASSADOR: "AMB",
  VENDOR: "VEN",
};

export const CORPORATE_CLUB_STATUS = {
  REVIEW: "REW",
  APPROVED: "APR",
  REJECTED: "REJ",
};

export const  VENDOR_STATUS = {
    ACTIVE: "ACTIVE",
    DISABLED: "DISABLED",
    REVIEW: "IN REVIEW",
}

export const AMBASSADOR_STATUS = {
  REVIEW: "In Review",
  APPROVED: "Approved",
  REJECTED: "Rejected",
};

export const ACCOUNT_TYPE_NAME = {
  CCM: "Corporate Club Member",
  CM: "Club Member",
  AMB: "Ambassador",
  VEN: "Vendor"
};

export const getRequiredValidation = (label) => ({
  required: true,
  message: `${label} is required`,
});

export const getEmailValidation = () => ({
  type: "email",
  message: `Enter valid mail address`,
});

export const getPhoneNumberValidation = (_, value) => {
  return !value || (value?.length >= 7 && value?.length < 16)
    ? Promise.resolve()
    : Promise.reject("Phone Number must be 8 to 15 character long");
};

export const getPasswordValidation = () => ({
  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  message: "Enter strong password",
});

export const getConfirmPasswordValidation =
  () =>
  ({ getFieldValue }) => ({
    validator: (_, value) => {
      if (!value || getFieldValue("new_password") === value) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error("The two passwords that you entered do not match!")
      );
    },
  });

export const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  } else if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const poorRegExp = /[A-Za-z]/;
const weakRegExp = /^(?=.*?[0-9#?!@$%^&*-])/;
const strongRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

export const passwordStrength = {
  WEAK: "Medium",
  POOR: "Weak",
  STRONG: "Strong",
};

export const getPasswordStrength = (value) => {
  const passwordValue = value;
  const poorPassword = poorRegExp.test(passwordValue);
  const weakPassword = weakRegExp.test(passwordValue);
  const strongPassword = strongRegExp.test(passwordValue);

  // to check strong Password
  if (poorPassword && weakPassword && strongPassword) {
    return passwordStrength.STRONG;
  }

  // to check weak password
  if (poorPassword && (weakPassword || strongPassword)) {
    return passwordStrength.WEAK;
  }

  // to check poor password
  if (poorPassword || weakPassword || strongPassword) {
    return passwordStrength.POOR;
  }

  return "";
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const dummyRequest = ({ onSuccess }) =>
  setTimeout(() => {
    onSuccess("ok");
  }, 0);

export const SOCIAL_MEDIA = {
  YOUTUBE: "YT",
  FACEBOOK: "FB",
  TWITTER: "TWIT",
  INSTAGRAM: "INSTA",
};

export const getSocialPlatformIcon = (key, fontSize = "14px") => {
  switch (key) {
    case SOCIAL_MEDIA.YOUTUBE:
      return (
        <>
          <YoutubeOutlined
            style={{
              marginBottom: "5px",
              marginRight: "5px",
              color: "#FF0000",
              fontSize,
            }}
          />{" "}
          Youtube
        </>
      );
    case SOCIAL_MEDIA.FACEBOOK:
      return (
        <>
          <FacebookOutlined
            style={{
              marginBottom: "5px",
              marginRight: "5px",
              color: "#4267B2",
              fontSize,
            }}
          />{" "}
          Facebook
        </>
      );
    case SOCIAL_MEDIA.INSTAGRAM:
      return (
        <>
          <InstagramOutlined
            style={{ marginBottom: "5px", marginRight: "5px", fontSize }}
          />{" "}
          Instagram
        </>
      );
    case SOCIAL_MEDIA.TWITTER:
      return (
        <>
          <TwitterOutlined
            style={{
              marginBottom: "5px",
              marginRight: "5px",
              color: "#00acee",
              fontSize,
            }}
          />{" "}
          Twitter
        </>
      );
    default:
      return null;
  }
};

export const removeEmptyData = (values) => {
  return {
    description: values.description,
    social_media_info: values.social_media_info
      .filter((social) => social.followers || social.link)
      .map((social) => ({
        account_type: social.account_type,
        followers: social.followers || undefined,
        link: social.link || undefined,
      })),
  };
};

export const DEFAULT_ERROR_MESSAGE = "Something went wrong, Please try again";

export const convertToLowerCase = (value) => String(value).toLocaleLowerCase();

export const stringSorter = (value1, value2) => {
  if (convertToLowerCase(value1) < convertToLowerCase(value2)) {
    return -1;
  }
  if (convertToLowerCase(value1) > convertToLowerCase(value2)) {
    return 1;
  }
  return 0;
};

export const dateSorter = (value1, value2) =>
  dayjs(value1).unix() - dayjs(value2).unix();

export const numberSorter = (value1, value2) => value1 - value2;

export const DATE_FORMAT = "YYYY-MM-DD";

export const INSIGHT_MONTH_FILTER = [
  { value: dayjs().add(-7, "days").format(DATE_FORMAT), label: "Last 1 Week" },
  {
    value: dayjs().add(-1, "month").format(DATE_FORMAT),
    label: "Last 1 Month",
  },
  {
    value: dayjs().add(-3, "months").format(DATE_FORMAT),
    label: "Last 3 Months",
  },
  {
    value: dayjs().add(-6, "months").format(DATE_FORMAT),
    label: "Last 6 Months",
  },
  { value: dayjs().add(-1, "year").format(DATE_FORMAT), label: "Last 1 Year" },
];

export const converToPercentage = (having, total) => (having / total) * 100;

export const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const getRememberedUser = () =>
  localStorage.getItem("longevity_club:remembered");

export const setRememberedUser = (value) =>
  localStorage.setItem("longevity_club:remembered", value);

export const generateQueryParams = (object) =>
  Object.keys(object).length
    ? "?" +
      Object.keys(object)
        .map((key) => {
          return `${key}=${encodeURIComponent(object[key])}`;
        })
        .join("&")
    : "";
