import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Tabs } from "antd";
import NftCollectionPage from "./NftCollectionPage";

const NftCollection = () => {
  const [activeTab, setActiveTab] = useState("nft");
  const tabItems = [
    {
      key: "nft",
      label: "My Longevity Assets",
      children: <NftCollectionPage />,
    },
  ];

  return (
    <div className="longevity-point-container">
      <Tabs
        items={tabItems}
        size="middle"
        tabBarStyle={{
          display: "flex !important",
          color: "#0064a9 !important",
          gap: "20px",
          margin: "0",
          justifyContent: "center !important",
        }}
        activeKey={activeTab}
        onChange={(value) => setActiveTab(value)}
      />
    </div>
  );
};

export default NftCollection;
