import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Tabs } from "antd";
import Points from "./Points";
import Miles from "./Miles";
import Donation from "./Donation";
import Crypto from "./Crypto";
import Insurance from "./Insurance";
import LongevityAssets from "./LongevityAssets";

const ExchangePointsPage = () => {
  const [activeTab, setActiveTab] = useState("points");
  const tabItems = [
    {
      key: "points",
      label: "Points",
      children: <Points />,
    },
    {
      key: "insurance",
      label: "Insurance",
      children: <Insurance setActiveTab={setActiveTab} />,
    },
    {
      key: "crypto",
      label: "Crypto",
      children: <Crypto setActiveTab={setActiveTab} />,
    },
    {
      key: "donation",
      label: "Donation",
      children: <Donation setActiveTab={setActiveTab} />,
    },
    {
      key: "longevityAssets",
      label: "Longevity Assets",
      children: <LongevityAssets setActiveTab={setActiveTab} />,
    },
  ];

  return (
    <div className="longevity-point-container">
      <Tabs
        items={tabItems}
        size="middle"
        tabBarStyle={{
          display: "flex !important",
          color: "#0064a9 !important",
          gap: "20px",
          margin: "0",
          justifyContent: "center !important",
        }}
        activeKey={activeTab}
        onChange={(value) => setActiveTab(value)}
      />
    </div>
  );
};

export default ExchangePointsPage;
