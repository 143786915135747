import { useGetLoyaltyMembersQuery } from "../../services/qibeeRewardsApi";
import Loader from "../Common/Loader";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { Card, Button, Avatar } from "antd";
import { truncateToSixDigits } from "../digits";

const { Meta } = Card;

const Donation = ({ setActiveTab }) => {
  const navigate = useNavigate();
  const { data: data, isLoading } = useGetLoyaltyMembersQuery();

  if (isLoading) {
    return <Loader />;
  }

  const handleWebsiteLink = (url) => {
    window.open(url, "_blank");
  };

  const handleExchangeClick = (item, value) => {
    navigate("/exchange", {
      state: {
        item,
        value,
      },
    });
  };

  return (
    <div className="card-container">
      {data?.data?.donation?.map((item) => {
        const brand = item.to_token.brand;
        const textBelowName = `${item.to_token.symbol} by ${brand.name}`;
        const exchangeRate = `1LCP=${truncateToSixDigits(
          item.from_token.offramp / item.to_token.onramp
        )} ${item.to_token.symbol}`;
        return (
          <Card key={item.id} className="card" size="small">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "15px",
              }}
            >
              <Avatar size={170} src={brand.brand_config.logo_url} />
            </div>
            <div className="card-body">
              <Meta title={brand.name} description={textBelowName} />
              <p style={{ marginBottom: "0", color: "#8C8C8C" }}>
                Exchange Rate
              </p>
              <p style={{ marginTop: "0", color: "#8C8C8C" }}>{exchangeRate}</p>
              <div className="button-container">
                <Button
                  style={{ width: "auto" }}
                  type="primary"
                  onClick={() => handleExchangeClick(item, "donation")}
                >
                  Exchange
                </Button>
                <Button
                  style={{ width: "auto" }}
                  type="secondary"
                  onClick={() =>
                    handleWebsiteLink(brand.brand_config.website_url)
                  }
                >
                  Website
                </Button>
              </div>
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default Donation;
