export function truncateToSixDigits(number) {
  const numberString = String(number);
  const decimalIndex = numberString.indexOf(".");
  if (decimalIndex !== -1) {
    const integerPart = numberString.slice(0, decimalIndex);
    const fractionalPart = numberString.slice(decimalIndex + 1);
    const significantDigitsNeeded = 6 - integerPart.length;
    const truncatedFractionalPart = fractionalPart.slice(
      0,
      significantDigitsNeeded
    );
    return integerPart + "." + truncatedFractionalPart;
  } else {
    return numberString;
  }
}
