import { useGetNftCollectionsQuery } from "../../services/nftCollectionsApi";
import Loader from "../Common/Loader";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { Card, Button, Avatar, Row, Typography } from "antd";
import { useSelector } from "react-redux";
const { Meta } = Card;
const { Paragraph, Title, Text } = Typography;

const NftCollectionPage = () => {
    const user = useSelector((state) => state.user.data);
    const { data: data, isLoading } = useGetNftCollectionsQuery();
    const navigate = useNavigate()
    if (isLoading) {
        return <Loader />;
    }

    const handleWebsiteLink = (url) => {
        window.open(url, "_blank");
    };

    const handleExchangeClick = (item) => {
        navigate("/exchange", {
            state: {
                item,
            },
        });
    };
    

    return (
        <div className="card-container">
            {data?.nft_list?.data?.map((item) => {
                const brand = item.token;
                // const textBelowName = `${item.to_token.symbol} by ${brand.name}`;
                return (
                    <Card key={item.id} className="card" size="small">
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: "15px",
                            }}
                        >
                            <Avatar size={170} src={brand.metadata.media.url} />
                        </div>
                        <div className="card-body">
                            {/* user */}
                            <Meta title={`${brand.name} - ${user?.first_name} ${user?.last_name}`} description="Longevity Club" />
                            <p>
                                <strong>Type:</strong> {brand.nft_type}
                            </p>
                            <p>
                                <strong>Description:</strong> {brand.metadata.description.data}
                            </p>
                            {brand.metadata.description.data == "" ? <br /> : null}
                            { }
                            <div className="button-container" style={{textAlign:"center"}}>
                                <Button
                                    style={{ width: "auto" }}
                                    type="secondary"
                                    onClick={() =>
                                        handleWebsiteLink(brand?.brand_config?.website_url || "https://app.longevity.group/redeem/exchange-points")
                                    }
                                >
                                    Website
                                </Button>
                            </div>
                        </div>
                    </Card>
                );
            })}
        </div>
    );
};

export default NftCollectionPage;
