const insurance = {
    "insurance": [
        {
            "name": "HUMN Pharmaceuticals",
            "description": "HUMN Pharmaceuticals was created to develop highly effective and clinically proven solutions.",
            "logo_url": "https://platform.dkv.global/files/companies/humn-pharmaceuticals.jpeg"
        },
        {
            "name": "GetActive",
            "description": "GetActive Walking is our lifestyle.is our lifestyle.is our lifestyle.",
            "logo_url": "https://platform.dkv.global/files/companies/getactive-2.png"
        },
        {
            "name": "Universal Medical Transfer Services",
            "description": "24 Hours Medical Emergency Flights with Medical Escorts",
            "logo_url": "https://platform.dkv.global/files/companies/Universal%20Medical%20Transfer%20Services.jpeg"
        },
        {
            "name": "Menath Insurance",
            "description": "Menath Insurance provider of commercial, personal and specialty insurance services.",
            "logo_url": "https://platform.dkv.global/files/companies/menath-insurance.jpeg"
        },
        {
            "name": "LifeCourse",
            "description": "LifeCourse is develop innovative concepts in the areas of lifestyle, insurance, health and welfare.",
            "logo_url": "https://platform.dkv.global/files/companies/lifecourse.jpeg"
        },
        {
            "name": "Lourie Life & Health",
            "description": "Lourie Life & HealthLourie Life & Health is an insurance broker that offers individual life & health insurance services for people of all ages.",
            "logo_url": "https://platform.dkv.global/files/companies/lourie-life-health.png"
        },
        {
            "name": "The Insurance Surgery",
            "description": "The Insurance Surgery is a Life Insurance and Travel Insurance expert as well as one of the top specialists.",
            "logo_url": "https://platform.dkv.global/files/companies/the-insurance-surgery.png"
        },
    ]
}


export const dummyObj = {
    "from_token": {
        "address": "f6118a81d0520ca6878d515f062e2832bd071a32",
        "available_supply": null,
        "brand": {
            "brand_config": {
                "logo_url": "https://www.intuitionconnect.com/files/facilities/1681399652_The_Harley_Street_Clinic_-_profile_square_April_2023.png",
                "loyalty_program_url": null,
                "website_url": "https://test.longevity.cards"
            },
            "id": "bb507d6ffc9b",
            "locations": [],
            "name": "Longevity Card"
        },
        "brand_id": "bb507d6ffc9b",
        "can_redeem": null,
        "can_sell": null,
        "can_sell_on_lad": null,
        "can_transfer": null,
        "countries": [],
        "enable_purchase_points": false,
        "estimated_member_count_higher": 10000,
        "estimated_member_count_lower": 1,
        "id": 93,
        "inserted_at": "2023-04-24T15:26:33",
        "metadata": {
            "coverImage": null,
            "description": {
                "data": "Longevity Club / Longevity Card reward points",
                "encoding": "text"
            },
            "exclusive_content": null,
            "external_link": null,
            "images": {},
            "legacy": {},
            "links": {},
            "media": {}
        },
        "name": "Longevity Point",
        "nft_type": null,
        "offramp": "0.95000",
        "onramp": "0.012500000000000000",
        "rank": 4,
        "sales_fee_percentage": null,
        "status": "active",
        "stripe_customer_id": "O5xe71Ab7r",
        "supply": null,
        "symbol": "LCP",
        "type": "erc20"
    },
    "id": 36,
    "max_usd_exchange_limit": "100.000000000000000000",
    "min_usd_exchange_limit": "0.010000000000000000",
    "status": "active",
    "timestamp": "2023-04-25T19:13:22",
    "to_token": {
        "brand": {
            "brand_config": {
                "logo_url": "https://www.intuitionconnect.com/files/facilities/1681399652_The_Harley_Street_Clinic_-_profile_square_April_2023.png",
                "loyalty_program_url": null,
                "website_url": "https://www.coinbase.com/"
            },
            "id": "dd37d68a1dc4",
            "locations": [],
            "name": "Harley Club"
        },
        "countries": [],
        "estimated_member_count_higher": 0,
        "estimated_member_count_lower": 10000000,
        "id": 24,
        "metadata": {
            "coverImage": null,
            "description": {
                "data": "The world’s first widely-adopted cryptocurrency. With Bitcoin, people can securely and directly send each other digital money on the internet.",
                "encoding": "text"
            },
            "exclusive_content": null,
            "external_link": null,
            "images": {},
            "legacy": {
                "description": "The world’s first widely-adopted cryptocurrency. With Bitcoin, people can securely and directly send each other digital money on the internet."
            },
            "links": {},
            "media": {}
        },
        "name": "Harley Club",
        "nft_type": null,
        "onramp": "1",
        "status": "active",
        "symbol": "HLC",
        "type": "crypto"
    },
    "exchange_rate": "0.0000002325",
    "user_membership_status": "inactive",
    "membership_number": null
}


export default insurance;