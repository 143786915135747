import React from "react";
import { Card, Row, Col, Typography } from "antd";
import { useGetUserTransactionsQuery } from "../../services/transactionsApi";

const { Paragraph, Title, Text } = Typography;

const TransactionCard = () => {
  const { data: transactionData } = useGetUserTransactionsQuery();

  function truncateToSixDigits(number) {
    const numberString = String(number);
    const decimalIndex = numberString.indexOf(".");
    if (decimalIndex !== -1) {
      const integerPart = numberString.slice(0, decimalIndex);
      const fractionalPart = numberString.slice(decimalIndex + 1);
      const significantDigitsNeeded = 6 - integerPart.length;
      const truncatedFractionalPart = fractionalPart.slice(
        0,
        significantDigitsNeeded
      );
      return integerPart + "." + truncatedFractionalPart;
    } else {
      return numberString;
    }
  }

  return (
    <div
      style={{
        width: "90%",
        margin: "20px auto",
      }}
    >
      <div className="offers-name">Transactions</div>
      {transactionData?.transactions?.map((item) => {
        const formattedAmount = truncateToSixDigits(item.amount_received);

        const date = new Date(item.inserted_at);
        const formattedDate = `${date.getDate()}.${
          date.getMonth() + 1
        }.${date.getFullYear()} - ${date.getHours()}:${date
          .getMinutes()
          .toString()
          .padStart(2, "0")}`;
        return (
          <Card
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "20px",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <div>
                <Title
                  strong
                  level={5}
                  style={{ margin: "0", color: "#717171" }}
                >
                  Date
                </Title>
                <Paragraph style={{ color: "#717171" }}>
                  {formattedDate}
                </Paragraph>
                <Title
                  strong
                  level={5}
                  style={{ margin: "0", color: "#717171" }}
                >
                  Exchange
                </Title>
                <Paragraph style={{ color: "#717171" }}>
                  {item.points_burned}
                </Paragraph>
                <Title
                  level={5}
                  strong
                  style={{ margin: "0", color: "#717171" }}
                >
                  Status
                </Title>
                <Paragraph style={{ color: "#717171" }}>
                  {item.status}
                </Paragraph>
              </div>
              <div>
                <Title
                  strong
                  level={5}
                  style={{ margin: "0", color: "#717171" }}
                >
                  Sent to
                </Title>
                <Paragraph style={{ color: "#717171" }}>
                  {item.membership_number_to.substring(0, 19)} 
                </Paragraph>
                <Title style={{ marginTop: "20px", color: "#1BB6E7" }}>
                  +{formattedAmount}{" "}
                  <Text style={{ fontSize: "1rem", color: "#1BB6E7" }}>
                    {" "}
                    {item.token_to.token_symbol}{" "}
                  </Text>
                </Title>
                <Paragraph
                  style={{
                    marginTop: "20px",
                    color: "#1BB6E7",
                    fontSize: "1rem",
                  }}
                >
                  {item.token_to.token_name}
                </Paragraph>
              </div>
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default TransactionCard;
