import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { marketplaceApi } from "../services/marketplaceApi";
import { userApi, protectedUserApi } from "../services/userApi";

import userReducer from "./userSlice";

import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { healthApi } from "../services/healthApi";
import { rewardApi } from "../services/rewardApi";
import { mindfulnessApi } from "../services/mindfulnessApi";
import { cancerApi } from "../services/cancerApi";
import { healthcheckApi } from "../services/prostatecheckAPI";
import { SearchRecipeApi } from "../services/nutritionadviceApi";
import { storiesApi } from "../services/storyApi";
import { connectedDevicesApi } from "../services/connectdevicesAPI";
import { MealPlanApi } from "../services/mealplanApi";
import { fitnessApi } from "../services/fitnessApi";
import { onboardingQuestionnaireApi } from "../services/onboardingQuestionnaireApi";
import { ambQuestionnaireApi } from "../services/ambQuestionnaireApi";
import { qibeeRewardsApi } from "../services/qibeeRewardsApi";
import { nftCollectionsApi } from "../services/nftCollectionsApi";
import { transactionsApi } from "../services/transactionsApi";
import { ambassadorDashboardApi } from "../services/ambassadorDBApi";
import { vendorDashboardApi } from "../services/vendorDBApi";
import { pathWayApi } from "../services/pathwaysApi";
import { feedbacksForm } from "../services/userFeedbacks";
import { userSegmentsAPI } from "../services/userSegmentsApi";
import { adminTools } from "../services/adminToolsAPI";

const rootReducer = combineReducers({
  user: userReducer,
  [userApi.reducerPath]: userApi.reducer,
  [marketplaceApi.reducerPath]: marketplaceApi.reducer,
  [protectedUserApi.reducerPath]: protectedUserApi.reducer,
  [healthApi.reducerPath]: healthApi.reducer,
  [MealPlanApi.reducerPath]: MealPlanApi.reducer,
  [rewardApi.reducerPath]: rewardApi.reducer,
  [mindfulnessApi.reducerPath]: mindfulnessApi.reducer,
  [cancerApi.reducerPath]: cancerApi.reducer,
  [healthcheckApi.reducerPath]: healthcheckApi.reducer,
  [SearchRecipeApi.reducerPath]: SearchRecipeApi.reducer,
  [storiesApi.reducerPath]: storiesApi.reducer,
  [connectedDevicesApi.reducerPath]: connectedDevicesApi.reducer,
  [fitnessApi.reducerPath]: fitnessApi.reducer,
  [onboardingQuestionnaireApi.reducerPath]: onboardingQuestionnaireApi.reducer,
  [ambQuestionnaireApi.reducerPath]: ambQuestionnaireApi.reducer,
  [qibeeRewardsApi.reducerPath]: qibeeRewardsApi.reducer,
  [nftCollectionsApi.reducerPath]: nftCollectionsApi.reducer,
  [transactionsApi.reducerPath]: transactionsApi.reducer,
  [ambassadorDashboardApi.reducerPath]: ambassadorDashboardApi.reducer,
  [vendorDashboardApi.reducerPath]: vendorDashboardApi.reducer,
  [pathWayApi.reducerPath]: pathWayApi.reducer,
  [feedbacksForm.reducerPath]: feedbacksForm.reducer,
  [userSegmentsAPI.reducerPath]: userSegmentsAPI.reducer,
  [adminTools.reducerPath]: adminTools.reducer

});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(marketplaceApi.middleware)
      .concat(userApi.middleware)
      .concat(healthApi.middleware)
      .concat(rewardApi.middleware)
      .concat(protectedUserApi.middleware)
      .concat(mindfulnessApi.middleware)
      .concat(cancerApi.middleware)
      .concat(healthcheckApi.middleware)
      .concat(SearchRecipeApi.middleware)
      .concat(storiesApi.middleware)
      .concat(connectedDevicesApi.middleware)
      .concat(MealPlanApi.middleware)
      .concat(fitnessApi.middleware)
      .concat(onboardingQuestionnaireApi.middleware)
      .concat(ambQuestionnaireApi.middleware)
      .concat(qibeeRewardsApi.middleware)
      .concat(nftCollectionsApi.middleware)
      .concat(transactionsApi.middleware)
      .concat(ambassadorDashboardApi.middleware)
      .concat(vendorDashboardApi.middleware)
      .concat(pathWayApi.middleware)
      .concat(feedbacksForm.middleware)
      .concat(userSegmentsAPI.middleware)
      .concat(adminTools.middleware),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);
