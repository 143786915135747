import { LoadingOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Typography,
  Upload,
} from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useLogoutAndBlockTokenMutation
} from "../../services/userApi";
import "./index.scss";

import {
  AMBASSADOR_STATUS,
  DATE_FORMAT,
  beforeUpload,
  dummyRequest,
  getBase64,
  getPhoneNumberValidation,
  getRequiredValidation,
} from "../../utils";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  updateUser,
  updateUserCountry,
  updateAccountType,
} from "../../store/userSlice";
import SocialMediaInformationPopup from "../../components/SocialMediaInformation/SocialMediaInformationPopup";
import { Link, useNavigate } from "react-router-dom";

import countryCodes from "country-codes-list";
import ReactCountryFlag from "react-country-flag";
import { stringSorter } from "../../utils";
import {
  useClientCodeGenerateMutation,
  useDisconnectToTerraWidgetMutation,
  useGetAllTerraDevicesQuery,
} from "../../services/connectdevicesAPI";
import { GlobalLoader } from "../../components/Common/Loader";
import { ReactComponent as MobilePhoneIcon } from "../../assets/svg/fa_mobile-phone.svg";
import { useAuth0 } from "@auth0/auth0-react";

const { Paragraph } = Typography;

const Profile = () => {
  const user = useSelector((state) => state.user.data);
  const client_Code = user?.health_client_code;
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState(undefined);
  const [imagePostUrl, setImagePostUrl] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [socialInfoModal, setSocialInfoModal] = useState(false);
  const [clientCode, setClientCode] = useState(client_Code || "");
  const [connectedDevices, setConnectedDevices] = useState([]);
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const [generateClientCode, { isLoading: isGenerateCodeLoading }] =
    useClientCodeGenerateMutation();
  const [ logoutAndBlockToken, { isLoading:logOutIsLoading }] = useLogoutAndBlockTokenMutation();
  const generateClientCodeHandler = useCallback(async () => {
    if (!client_Code) {
      const payload = {
        email: user?.email,
        username: user?.first_name + " " + user?.last_name,
      };
      const response = await generateClientCode({ ...payload });
      setClientCode(response?.data?.client_code);
      sessionStorage.setItem(
        "client_code",
        JSON.stringify(response?.data?.client_code)
      );
    }
  }, [
    client_Code,
    generateClientCode,
    user?.email,
    user?.first_name,
    user?.last_name,
  ]);

  const onLogout = useCallback(() => {
    logoutAndBlockToken({})
    dispatch(updateUser(null));
    dispatch(updateAccountType(null));
    logout({ openUrl: false });
    window.location.href = "/login";
  }, [logout]);

  useEffect(() => {
    if (!client_Code) {
      generateClientCodeHandler();
    }
  }, [client_Code]);

  const dispatch = useDispatch();
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const { data, refetch } = useGetProfileQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );


  useEffect(() => {
    document.title = "Profile Settings | Longevity Club";
  }, []);

  const {
    data: terraDevices,
    isLoading: isAPILoading,
    refetch: dataRefetch,
  } = useGetAllTerraDevicesQuery(clientCode);
  const [disconnectWidget, { isLoading: isDisconnectWidgetLoading }] =
    useDisconnectToTerraWidgetMutation();

  const handleDisconnectDevice = async (device) => {
    // setIsLoading(true);

    try {
      const response = await disconnectWidget({
        client_code: clientCode,
        terraUserId: device.user_id,
      });
      if (response.data && response.data.status === "success") {
        setConnectedDevices([...connectedDevices, device]);
        await dataRefetch();
        notification.success({
          type: "success",
          message: `Disconnected to ${device.name}.`,
        });
      }
    } catch (error) {
      notification.error({
        type: "error",
        message: `Failed to disconnect from ${device.name}.`,
      });
    }
  };

  const updateData = useCallback(
    ({
      date_of_birth,
      first_name,
      last_name,
      gender,
      profile_pic,
      email,
      phone_code,
      phone_number,
      country,
    }) => {
      form.setFieldsValue({
        first_name,
        last_name,
        gender,
        email,
        country: country || undefined,
        date_of_birth: date_of_birth
          ? dayjs(date_of_birth, DATE_FORMAT)
          : undefined,
        phone_number,
        phone_code,
      });
      setImageUrl(profile_pic);
    },
    [setImageUrl, form]
  );

  useEffect(() => {
    if (data) {
      updateData(data);
    }
  }, [data, updateData]);

  const onSubmit = (values) => {
    const form = new FormData();
    imagePostUrl && form.append("profile_pic", imagePostUrl);
    form.append("first_name", values.first_name);
    form.append("last_name", values.last_name);
    form.append("gender", values.gender);
    form.append("country", values.country || "");
    form.append(
      "date_of_birth",
      values.date_of_birth ? values.date_of_birth.format(DATE_FORMAT) : ""
    );

    if (values.phone_code && values.phone_number) {
      form.append("phone_code", values.phone_code);
      form.append("phone_number", values.phone_number);
    } else {
      form.append("phone_code", "");
      form.append("phone_number", "");
    }

    updateProfile(form)
      .then(({ data, error }) => {
        if (error?.data?.error) {
          message.error(
            error?.data?.error || "Something went wrong, Please try again"
          );
        } else {
          message.success("Profile has been updated successfully !!");
          refetch();
          dispatch(updateUser(data.token));
          dispatch(updateUserCountry(data.country_code));
        }
      })
      .catch(() => {
        message.error("Something went wrong, Please try again");
      });
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setImagePostUrl(info.file.originFileObj);
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const showBeAnAmbassador = useMemo(
    () =>
      !user?.ambassador_request ||
      user?.ambassador_request === AMBASSADOR_STATUS.REJECTED,
    [user]
  );

  const showAmbassadorProgramm = useMemo(
    () =>
      !!user?.ambassador_request &&
      user?.ambassador_request !== AMBASSADOR_STATUS.REJECTED,
    [user]
  );

  const countriesObject = countryCodes.customList(
    "countryCode",
    "{countryNameEn}"
  );

  const countriesWithCallingCodeObject = countryCodes.customList(
    "countryCallingCode",
    "{countryCode}"
  );

  const countriesWithCallingCode = useMemo(
    () =>
      Object.keys(countriesWithCallingCodeObject)
        .map((countryName) => ({
          value: `+${countryName}`,
          label: countriesWithCallingCodeObject[countryName],
        }))
        .sort((a, b) => stringSorter(a.label, b.label)),
    [countriesWithCallingCodeObject]
  );

  const countries = useMemo(
    () =>
      Object.keys(countriesObject)
        .map((countryCode) => ({
          value: countriesObject[countryCode],
          label: countriesObject[countryCode],
          code: countryCode,
        }))
        .sort((a, b) => stringSorter(a.label, b.label)),
    [countriesObject]
  );

  if (isLoading || isGenerateCodeLoading || isAPILoading) {
    return <GlobalLoader />;
  }

  return (
    <div className="profile-container">
      <div className="profile-title">Profile</div>

      <Paragraph className="sub-title">
        This information will be displayed publicly so be careful what you
        share.
      </Paragraph>
      {showBeAnAmbassador && (
        <Paragraph
          className="sub-title be-an-ambassador"
          onClick={() => setSocialInfoModal(true)}
        >
          Join our ambassador program
        </Paragraph>
      )}

      {showAmbassadorProgramm && (
        <Paragraph
          className="sub-title be-an-ambassador"
          onClick={() => navigate("/ambassador-program")}
        >
          Ambassador program
        </Paragraph>
      )}
      <div className="profile-wrapper">
        <Form
          form={form}
          className="form-container"
          onFinish={onSubmit}
          layout="vertical"
          initialValues={{ phone_code: "+44" }}
        >
          <Row gutter={[20]}>
            <Col span={24}>
              <Form.Item
                name="photo"
                label="Photo"
                className="form-item"
                required={false}
              >
                <Upload
                  name="avatar"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  maxCount={1}
                  customRequest={dummyRequest}
                  className="avatar-uploader"
                >
                  <div className="upload-btn">
                    <Avatar
                      size={48}
                      shape={"circle"}
                      icon={
                        loading ? (
                          <LoadingOutlined />
                        ) : imageUrl ? (
                          <img src={imageUrl} alt="avatar" />
                        ) : (
                          <UserOutlined />
                        )
                      }
                    />
                    <Button type="secondary" className="change-btn">
                      Change
                    </Button>
                  </div>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="email" label="Email" className="form-item">
                <Input placeholder="Email" disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="first_name"
                label="First Name"
                className="form-item"
                rules={[{ ...getRequiredValidation("First Name") }]}
                required={false}
              >
                <Input placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="last_name"
                label="Last Name"
                className="form-item"
                rules={[{ ...getRequiredValidation("Last Name") }]}
                required={false}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </Col>
            <Col md={24} sm={24} span={24}>
              <Form.Item
                name="gender"
                label="Gender"
                className="form-item"
                rules={[{ ...getRequiredValidation("Gender") }]}
                required={false}
              >
                <Radio.Group
                  style={{
                    width: "100%",
                  }}
                >
                  <Row gutter={[20, 20]}>
                    <Col md={12} sm={12} span={12}>
                      <Radio value="M" className="radio-item">
                        Male
                      </Radio>
                    </Col>
                    <Col md={12} sm={12} span={12}>
                      <Radio value="F" className="radio-item">
                        Female
                      </Radio>
                    </Col>
                    <Col md={24} sm={24} span={24}>
                      <Radio value="PNTS" className="radio-item">
                        Prefer not to say
                      </Radio>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="date_of_birth"
                label="Birthday"
                className="form-item"
                required={false}
              >
                <DatePicker
                  format={DATE_FORMAT}
                  popupClassName="dob-picker"
                  placeholder="Birthday"
                  disabledDate={(current) =>
                    current && current > dayjs().endOf("day")
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item shouldUpdate className="should-update-wrapper">
                {() => {
                  return (
                    <Form.Item
                      label="Phone Number"
                      className="phone-space-wrapper"
                    >
                      <Space.Compact className="phone-space">
                        <Form.Item
                          name="phone_code"
                          className="form-item phone-code-input"
                          required={false}
                        >
                          <Select
                            placeholder="Select Code"
                            showSearch
                            size="large"
                            filterOption={(va, al) =>
                              al?.children?.[2]
                                ?.toLowerCase()
                                .includes(va?.toLowerCase())
                            }
                          >
                            {countriesWithCallingCode
                              ?.filter(({ label }) => !["AN"].includes(label))
                              ?.map(({ value, label }) => (
                                <Select.Option value={value}>
                                  <ReactCountryFlag
                                    countryCode={label}
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    aria-label="United States"
                                  />{" "}
                                  {label}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="phone_number"
                          className="form-item phone-number-input"
                          required={false}
                          rules={[
                            {
                              validator: getPhoneNumberValidation,
                            },
                            {
                              pattern: /^[0-9]*$/,
                              message: "Only digits are allowed",
                            },
                          ]}
                        >
                          <Input prefix={form.getFieldValue("phone_code")} />
                        </Form.Item>
                      </Space.Compact>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="country"
                label="Country of Residence"
                className="form-item"
                required={false}
              >
                <Select
                  placeholder="Please Country of Residence"
                  allowClear
                  showSearch
                  size="large"
                >
                  {countries
                    ?.filter(({ code }) => !["AN"].includes(code))
                    ?.map(({ value, label, code }) => (
                      <Select.Option value={value}>
                        <ReactCountryFlag
                          countryCode={code}
                          style={{
                            fontSize: "14px",
                          }}
                          aria-label={label}
                        />{" "}
                        {label}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Link to="/change-password" style={{ textAlign:"center"}}><h4>Change password</h4></Link>
            </Col>
            <Divider />
            <Col span={24}>
              <Form.Item
                name="signin-btn"
                className="form-item profile-btn-container"
              >
                <Button type="secondary" onClick={() => updateData(data)}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Update profile
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="device-head-section">
        <div className="devices-title">My devices</div>
        {/* <Button
          onClick={() => handlePostTerra()}
          icon={<PlusOutlined />}
          type="primary"
          className="connect-device-button"
        >
          Connect My Devices
        </Button> */}
      </div>
      <div className="devices-section">
        {terraDevices
          ? terraDevices
            ?.filter((data) => data.user_id)
            ?.map((device) => (
              <div className="device-listing-wrapper" key={device.id}>
                <div className="device-1">
                  <div className="get-points-logo">
                    <img src={device?.logo} alt="logo" />
                  </div>
                  <div className="device-name-text">{device?.name}</div>
                </div>
                <div className="btn-wrapper">
                  <Button
                    className="disconnect-btn"
                    disabled={isDisconnectWidgetLoading}
                    onClick={() => {
                      handleDisconnectDevice(device);
                    }}
                  >
                    Disconnect
                  </Button>
                </div>
              </div>
            ))
          : null}
        <div className="device-listing-wrapper-button">
          <div className="device-1">
            <MobilePhoneIcon />
          </div>
          <Button
            onClick={() => navigate("/settings/profile/add-new-device")}
            // onClick={() => setIsAddNewDeviceModalOpen(true)}
            type="primary"
            className="connect-device-button"
          >
            <PlusOutlined />
            Add new device
          </Button>
        </div>
        {terraDevices?.filter((data) => data.user_id).length === 0 ? (
          <div className="empty-devices">No Devices Found</div>
        ) : null}
      </div>
      <br />
      <div>
        <Button className="profile-logout-button" onClick={onLogout}>
          Logout from my profile
        </Button>
      </div>
      <br />

      <SocialMediaInformationPopup
        open={socialInfoModal}
        onFinish={() => setSocialInfoModal(false)}
        onClose={() => setSocialInfoModal(false)}
      />
    </div>
  );
};

export default Profile;
