import React, { useEffect } from "react";
import { Col, Form, Row, Input, Button, Checkbox, Select, Radio } from "antd";
import { Option } from "antd/es/mentions";
import {
  SOCIAL_MEDIA,
  getRequiredValidation,
  getSocialPlatformIcon,
} from "../../utils";
import { useCreateAmbQuestionnaireAnswerMutation, useGetAmbCreateAnswerQuery, useGetAmbQuestionnaireQuery } from "../../services/ambQuestionnaireApi";
import { useNavigate } from "react-router-dom";

const { TextArea } = Input;

export const defaultValues = {
  description: "",
  social_media_info: [
    { account_type: SOCIAL_MEDIA.YOUTUBE },
    { account_type: SOCIAL_MEDIA.FACEBOOK },
    { account_type: SOCIAL_MEDIA.INSTAGRAM },
    { account_type: SOCIAL_MEDIA.TWITTER },
  ],
};

export const FormFields = ({ form }) => {
  const navigate = useNavigate();
  const { data: questionnaire, isLoading: questionnaireLoader } = useGetAmbQuestionnaireQuery();
  console.log(questionnaire, "ques")
  const [
    createQuestionnaireAnswer,
  ] = useCreateAmbQuestionnaireAnswerMutation();
  const { data: getOnboardingAnswer, isSuccess } =
    useGetAmbCreateAnswerQuery();

  const onboardingSubmittedAns = (formValues) => {
    const arr = Object.entries(formValues)?.map(([key, value]) => ({ key, value }));
    let answerArray = []
    arr && arr?.length && arr?.map((data) => {
      const que = questionnaire && questionnaire?.find((q) => q.id === Number(data.key));
      if (data?.value && que?.id) {
        answerArray.push({
          question_id: que.id,
          selected_answers: que?.question_type === 'Single Choice' ?
            !Array.isArray(data?.value?.radioAns) ?
              [data?.value?.radioAns] :
              data.value?.radioAns?.map(k => {
                return (k)
              }) :
            [],
          text_answer: que?.question_type === "Text Field" ? data.value : null,
        });
      }
      return answerArray;
    });
    return answerArray;
  };

  const handleFormSubmit = async (values) => {
    const onboardingAnswers = onboardingSubmittedAns(values);
    await createQuestionnaireAnswer(onboardingAnswers);
    if (isSuccess) {
      console.log("Navigating to /ambassador-program");
      navigate("/ambassador-program");
    }
  };
  
  const getQuestion = (question) => {
    if (question && question.question_type === "Single Choice" && question.options && question.options.length > 1) {
      return (
        <div className="question-container">
          <div className="question_wrappers" style={{ display: "flex" }}>
            {/* <div className="question-id"><span style={{ color: "red", marginRight: "3px" }}>*</span>0{question.id}</div> */}
            <div className="question-text">{question.question}</div>
          </div>
          <Form.Item
            name={[question.id, "radioAns"]}
            className="info-form"
            rules={[
              {
                required: true,
                message: `Please select at least one`,
              },
            ]}
          >
            <Radio.Group>
              {question?.options?.map((opt) => (
                <Radio value={opt.id} key={opt.id} className="info-label">
                  {opt.text}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </div>
      );
    } else if (question.question_type === "Text Field") {
      if (question.selector_options && question.selector_options.length > 0) {
        return (
          <div className="question-container">
            <div className="question_wrappers">
              {/* <div className="question-id"><span style={{ color: "red", marginRight: "3px" }}>*</span>0{question.id}</div> */}
              <div className="question-text">{question.question}</div>
            </div>
            <Form.Item
              name={question.id}
              className="info-form"
            >
              <Input.Group compact>
                <Form.Item
                  name={[question.id, "value"]}
                  noStyle
                  rules={[{ required: true, message: `This filed is required` }]}
                >
                  <Input placeholder={question.question} style={{ width: '60%' }} />
                </Form.Item>
                <Form.Item
                  name={[question.id, "text"]}
                  noStyle
                  rules={[{ required: true, message: `Please select an option for` }]}
                >
                  <Select placeholder="Unit" style={{ width: '40%' }}>
                    {question.selector_options?.map((opt) => (
                      <Option key={opt.id} value={opt.id}>
                        {opt.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </div>
        );
      } else {
        return (
          <div className="question-container">
            <div className="question_wrappers">
              {/* <div className="question-id"><span style={{ color: "red", marginRight: "3px" }}></span>0{question.id}</div> */}
              <div className="question-text">{question.question}</div>
            </div>
            <Form.Item
              name={question.id}
              className="info-form"
              rules={[
                {
                  required: true,
                  message: `This filed is required`,
                },
              ]}
            >
              <Input placeholder={question.question} />
            </Form.Item>
          </div>
        )
      }
    }
  }

  useEffect(() => {
    const valuesObject = {};
    getOnboardingAnswer &&
      getOnboardingAnswer?.forEach((item) => {
        const que = questionnaire && questionnaire?.find((q) => q.id === item.question_id)?.question_type;
        if (item?.text_answer) {
          valuesObject[item.question_id] = item.text_answer;
        }
        if (item?.selected_options) {
          valuesObject[item.question_id] = {
            text: item.selected_options[0]?.id,
            value: item?.text_answer,
            free_text: item?.text_answer,
          };
        }
        if (item?.selected_answers) {
          valuesObject[item.question_id] = {
            radioAns: (item.selected_answers.length === 1 && que?.includes("Single Choice")) ? item.selected_answers[0]?.id : item.selected_answers?.map((data) => data.id),
            free_text: item?.text_answer,
          };
        }
      });
    form?.setFieldsValue(valuesObject);
  }, [form, getOnboardingAnswer, questionnaire]);

  return (
    <>
      <Col md={24} sm={24} span={24}>
        <Form.Item
          name="description"
          className="form-item"
          rules={[{ ...getRequiredValidation("Description") }]}
          label="Description"
          required={false}
        >
          <TextArea
            rows={4}
            placeholder="Please, give us a brief description of why you would be a good fit for this Ambassador role."
          />
        </Form.Item>
      </Col>
      <Col md={24} sm={24} span={24}>
        <Form.Item
          name="termsAndConditions"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject("You must agree to the Ambassador Programme Terms");
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Checkbox>
            I have read and agree to the
            <a
              href="https://www.longevity.group/ambassador-programme"
              target="_blank"
              rel="noreferrer"
              style={{ cursor: "pointer", marginLeft: '5px' }}
            >
              Ambassador Programme Terms
            </a>
          </Checkbox>
        </Form.Item>
      </Col>
      <Col md={24} sm={24} span={24}>
        <Form.List name="social_media_info">
          {(fields) => (
            <Row gutter={[10, 10]} align="stretch">
              {fields.map((field, index) => (
                <Col sm={12} xs={24} key={field.key}>
                  <div>
                    {getSocialPlatformIcon(
                      form.getFieldValue("social_media_info")[index].account_type
                    )}
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues?.social_media_info?.[index]?.followers !==
                        curValues?.social_media_info?.[index]?.followers
                      }
                    >
                      {() => (
                        <Form.Item
                          name={[index, "link"]}
                          rules={[
                            {
                              type: "url",
                              message: "Invalid url",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value &&
                                  getFieldValue("social_media_info")[index]
                                    .followers
                                ) {
                                  return Promise.reject(
                                    new Error("Link is required")
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input placeholder="Link to your profile" />
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues?.social_media_info?.[index]?.link !==
                        curValues?.social_media_info?.[index]?.link
                      }
                    >
                      {() => (
                        <Form.Item
                          name={[index, "followers"]}
                          rules={[
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value &&
                                  getFieldValue("social_media_info")[index].link
                                ) {
                                  return Promise.reject(
                                    new Error("Follower count is required")
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            type="number"
                            min={0}
                            placeholder="Follower's count"
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </Form.List>
      </Col>

      <Col md={24} sm={24} span={24}>
        <Form
          form={form}
          className="custom-form"
          onFinish={handleFormSubmit}
        >
          {questionnaire?.length && questionnaire?.map((data) => (
            getQuestion(data)
          ))}
        </Form>
      </Col>
    </>
  )
};

const SocialMediaInformationForm = ({
  onSubmit,
  initialValues = defaultValues,
  isLoading,
  fromPopup = false,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return (
    <Form
      form={form}
      className="form-container social-information-form"
      onFinish={onSubmit}
      layout="vertical"
      initialValues={initialValues}
    >
      <Row gutter={fromPopup ? 0 : [20]} style={{ margin: "16px" }}>
        <FormFields form={form} />
        <Col md={24} sm={24} span={24}>
          <Form.Item className="form-item">
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: "10px" }}
              loading={isLoading}
              onClick={(e) => {
                e.preventDefault();
                form.submit();
              }}>
              Continue
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SocialMediaInformationForm;
