/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTerraUserDataMutation } from "../../services/connectdevicesAPI";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { GlobalLoader } from "../../components/Common/Loader";

const TerraConnect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isResponse, setIsResponse] = useState(false);
  const loginUserInfo = useSelector((state) => state?.user?.data);
  const clientCode = loginUserInfo?.health_client_code;

  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("user_id");
  const referenceId = searchParams.get("reference_id");
  const resource = searchParams.get("resource");

  const updatedClientCode = !clientCode
    ? JSON.parse(sessionStorage.getItem("client_code"))
    : clientCode;

  const apiPayload = {
    user_id: userId,
    reference_id: referenceId,
    resource,
  };

  const [terraUserData, { isLoading }] = useTerraUserDataMutation();
  const fetchTerraUserData = async () => {
    const myApiPayload = {
      client_code: updatedClientCode,
      ...apiPayload,
    };
    try {
      const response = await terraUserData(myApiPayload);
      if (response?.data) {
        setIsResponse(true);
        Swal.fire({
          title: "Successfully Connected",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "Close",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/settings/profile");
          }
        });
      }
      if (!isResponse && response?.error) {
        Swal.fire({
          title: "Failed!",
          text: "There is some problem , please try again",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "Close",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/settings/profile");
          }
        });
      }
    } catch (error) {
    }
  };
  useEffect(() => {
    fetchTerraUserData();
  }, []);
  if (isLoading) {
    return <GlobalLoader />;
  }

  return;
};

export default TerraConnect;
