import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./index.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  useAddStoryMutation,
  useDeleteStoryMutation,
  useEditStoryMutation,
  useGetStoryFromIdQuery,
} from "../../../services/storyApi";
import { Button, Form, Input, notification } from "antd";
import BaseLayout from "../BaseLayout";

const AddStory = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: storyDetails, refetch } = useGetStoryFromIdQuery(Number(id));
  const [addStory] = useAddStoryMutation();
  const [editStory] = useEditStoryMutation();
  const [deleteStory] = useDeleteStoryMutation();

  const [form] = Form.useForm();

  useEffect(() => {
    refetch();
    if (id && storyDetails) {
      form.setFieldsValue({
        title: storyDetails?.title,
        description: storyDetails?.description,
      });
    }
  }, [form, storyDetails, id, refetch]);

  const onFormSubmitHandler = async (values) => {
    let response;
    if (id) {
      const apiPayload = { id, ...values };
      response = await editStory(apiPayload);
    } else {
      response = await addStory(values);
    }
    if (response?.data) {
      notification.success({
        type: "success",
        message: `Story has been ${id ? "edited" : "added"} successfully`,
      });
      navigate(`/health/stories`);
    } else if (response?.error) {
      notification.error({
        type: "error",
        message: "Something Went Wrong!",
      });
    }
  };

  const handleStoryDelete = async () => {
    const response = await deleteStory(Number(id));
    if (response?.data) {
      navigate("/health/stories");
      window.location.reload()
      notification.success({
        type: "success",
        message: "Story Deleted",
      });
    } else if (response?.error) {
      notification.error({
        type: "error",
        message: "Something Went Wrong!",
      });
    }
  };

  const handleStoryNavigate = () => {
    if (id) {
      navigate(`/health/stories`);
    } else {
      navigate("/health/stories");
    }
  };

  return (
    <BaseLayout path="/health/stories">
      <div className="add-story-main-wrapper">
        <ArrowLeftOutlined
          className="back-arrow"
          onClick={handleStoryNavigate}
        />
        <div className="edit-story-add">
          <Form form={form} layout="vertical" onFinish={onFormSubmitHandler}>
            <div className="add-stories-main-wrapper">
              <div className="add-story-header">
                <div className="edit-story-title">
                  {!id ? "Add Story" : "Edit Story"}
                </div>
              </div>
              <div className="add-story-container">
                <Form.Item
                  name="title"
                  rules={[
                    !id && {
                      required: true,
                      message: "This Field Is Required",
                    },
                  ]}
                >
                  <Input
                    className="area-story"
                    id="story-textarea"
                    placeholder="Your Title"
                  />
                </Form.Item>
                <Form.Item
                  name="description"
                  rules={[
                    !id && {
                      required: true,
                      message: "This Field Is Required",
                    },
                  ]}
                >
                  <textarea
                    className="area-story"
                    rows={5}
                    cols={93}
                    placeholder={"Your Story"}
                    id="story-textarea"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="add-story-footer">
              <Button className="footer-btn" type="primary" htmlType="submit">
                Save
              </Button>
              {id && (
                <Button
                  className="footer-btn delete-btn"
                  type="primary"
                  danger
                  onClick={handleStoryDelete}
                >
                  Delete
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </BaseLayout>
  );
};

export default AddStory;
