import React, { useEffect, useMemo, useState } from "react";
import {
  PieChart,
  Pie,
  Tooltip,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Cell,
  Legend,
} from "recharts";
import {
  useGetAllPointsQuery,
  useLastOneMonthInsightsPointsQuery,
  useLongevityPointsQuery,
} from "../../services/rewardApi";
import { useSelector } from "react-redux";
import { Empty, Select } from "antd";

const chartOptions = [
  {
    value: "last_one_week",
    label: "Last 1 Week",
  },
  {
    value: "last_one_month",
    label: "Last 1 Month",
  },
  {
    value: "last_three_months",
    label: "Last 3 Months",
  },
  {
    value: "last_six_months",
    label: "Last 6 Months",
  },
  {
    value: "last_one_year",
    label: "Last 1 Year",
  },
];

const Insights = () => {
  const [selectedEventType, setSelectedEventType] = useState("");

  const user = useSelector((state) => state.user.data);

  const rewardsClientCode = user?.rewards_client_code;
  const [selectedChartOption, setSelectedChartOption] =
    useState("last_one_month");

  const {
    data: PointsData,
    isLoading,
    refetch,
  } = useLongevityPointsQuery({
    rewardsClientCode,
    selectedChartOption,
  });

  const { data: allPointsStack, isLoading: PointsLoader } =
    useGetAllPointsQuery({
      rewardsClientCode,
      selectedChartOption,
    });
  const { data: lastOneMonthInsightsPoints } =
    useLastOneMonthInsightsPointsQuery(user?.rewards_client_code);

  useEffect(() => {
    if (user?.rewards_client_code) {
      refetch();
    }
  }, [user, refetch]);

  const mapData = (data) =>
    data?.map((pre) => ({
      ...pre,
      label: pre?.event_type,
      value: Math.floor(pre?.points),
      points: Math.floor(pre?.points),
    }));

  const InsightsPointsResult = useMemo(() => mapData(PointsData), [PointsData]);

  const allEventTypeFilter = Array.from(
    new Set(allPointsStack?.map((el) => el?.event_type))
  );

  const filteredAllEventTypeFilter = allEventTypeFilter?.filter(
    (event) => event !== null
  );

  const transformedData = (allPointsStack || []).reduce((result, item) => {
    const { dt, event_type, points } = item;
    if (event_type) {
      if (!result[dt]) {
        result[dt] = { name: dt };
      }
      result[dt][event_type] = points;
    }

    return result;
  }, {});

  const combinedData = transformedData ? Object?.values(transformedData) : [];

  const CustomTooltipPieChart = ({ active, payload }) => {
    const dataItem = PointsData?.find(
      (item) => item?.event_type?.toString() === payload[0]?.name?.toString()
    );

    const getBackgroundColor = (index) => {
      return colors[index % colors?.length];
    };

    if (active && dataItem) {
      const eventTypeIndex = allEventTypeFilter?.indexOf(dataItem?.event_type);

      return (
        <div className="custom-tooltip">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderWidth: 1,
            }}
          >
            <div
              style={{
                width: "10px",
                backgroundColor: getBackgroundColor(eventTypeIndex),
                height: "10px",
                marginRight: "5px",
              }}
            ></div>
            {Math.floor(dataItem?.points)}
          </div>
        </div>
      );
    }

    return null;
  };

  const colors = [
    "#0064a9",
    "#1DB6E7",
    "#717171",
    "#B1C3D0",
    "#83adc9",
    "#6cadd9",
    "#95bbc4",
  ];

  const pieChartCells = colors?.map((color, index) => (
    <Cell key={index} fill={color} />
  ));

  const CustomizedAxisTick = ({ x, y, payload }) => {
    const formattedValue = formatMonthYear(payload?.value, selectedChartOption);
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={20}
          textAnchor="end"
          fontSize={13}
          transform="rotate(-30)"
        >
          {formattedValue}
        </text>
      </g>
    );
  };

  const formatMonthYear = (dateStr, selectedChartOption) => {
    const date = new Date(dateStr);

    if (
      selectedChartOption === "last_one_week" ||
      selectedChartOption === "last_one_month"
    ) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    } else if (selectedChartOption === "last_one_year") {
      const oneYearAgo = new Date(date);
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
      const year = oneYearAgo.getFullYear().toString();
      return `${date.toLocaleString("default", { month: "short" })}-${year}`;
    } else {
      return (
        date.toLocaleString("default", { month: "short" }) +
        "-" +
        date.getFullYear()
      );
    }
  };

  const style = {
    top: "-32%",
    display: "flex",
  };

  const customFilterFunction = () => {
    let arr = [];
    combinedData?.forEach((item) => {
      if (item[selectedEventType] > 0) {
        arr.push({
          name: item.name,
          [selectedEventType]: item[selectedEventType],
        });
      }
    });
    return arr;
  };

  const CustomTooltipBarChart = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-tooltip-bar-chart">
          <p>Date: {label}</p>
          {payload?.map((item) => (
            <p key={item.dataKey}>
              {item.dataKey}: {Math.floor(item.value)}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };
  // const CustomTooltipBarChart = ({ active, payload, label }) => {
  //   if (active) {
  //     return (
  //       <div className="custom-tooltip-bar-chart">
  //         <p>Date: {label}</p>
  //         {payload?.map((item) => {
  //           if (item && item?.dataKey) {
  //             return (
  //               <p key={item.dataKey}>
  //                 {item?.dataKey}: {Math.floor(item.value)}
  //               </p>
  //             );
  //           }
  //           return null;
  //         })}
  //       </div>
  //     );
  //   }
  //   return null;
  // };

  return (
    <div className="insights-container">
      <div className="dashboard-points">
        Distribution of Earned Longevity Points
      </div>
      <div className="dropdown-section-pie-chart">
        <Select
          placeholder="select any"
          value={selectedChartOption}
          onChange={(value) => setSelectedChartOption(value)}
          className="charts-dropdown"
          options={chartOptions}
        />
      </div>
      <div className="pie-chart-wrapper">
        {!isLoading && PointsData && PointsData?.length > 0 ? (
          <PieChart width={450} height={380}>
            <Pie
              data={InsightsPointsResult}
              dataKey="value"
              nameKey="label"
              outerRadius={150}
              label="points"
              background="#0064a9"
            >
              {pieChartCells}
            </Pie>
            <Tooltip content={<CustomTooltipPieChart />} />
            <Legend
              iconSize={10}
              layout="vertical"
              verticalAlign="end"
              wrapperStyle={style}
            />
          </PieChart>
        ) : (
          <Empty />
        )}
      </div>
      <div className="dashboard-bar-chart">
        <div className="dashboard-points">
          Progress of Earned Longevity Points
        </div>
        <div className="dropdown-section-bar-chart">
          <Select
            placeholder="All Reward Types"
            value={selectedEventType === "" ? "All Reward Types" : selectedEventType}
            onChange={(value) => {
              setSelectedEventType(value === "All Reward Types" ? "" : value);
            }}
            className="bar-chart-dropdown"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
            }
          >
            <Select.Option key="All Reward Types" value="All Reward Types">
              All Reward Types
            </Select.Option>
            {filteredAllEventTypeFilter?.map((event) => (
              <Select.Option key={event} value={event}>
                {event}
              </Select.Option>
            ))}
          </Select>
          {/* <Select
            placeholder="All Reward Types"
            value={
              selectedEventType === "" ? "All Reward Types" : selectedEventType
            }
            onChange={(value) => {
              setSelectedEventType(value === "All Reward Types" ? "" : value);
            }}
            className="bar-chart-dropdown"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
              0
            }
          >
            <Select.Option key="All Reward Types" value="All Reward Types">
              All Reward Types
            </Select.Option>
            <Select.Option key="Welcome" value="Welcome">
              Welcome
            </Select.Option>
            <Select.Option key="New Card" value="New Card">
              New Card
            </Select.Option>
            <Select.Option key="Payment" value="Payment">
              Payment
            </Select.Option>
            <Select.Option
              key="Explore Marketplace Offers"
              value="Explore Marketplace Offers"
            >
              Explore Marketplace Offers
            </Select.Option>
            <Select.Option
              key="Play Mindfulness Exercise Track"
              value="Play Mindfulness Exercise Track"
            >
              Play Mindfulness Exercise Track
            </Select.Option>
            <Select.Option key="Daily Steps Goal" value="Daily Steps Goal">
              Daily Steps Goal
            </Select.Option>
            <Select.Option
              key="Registration Via Referral Link"
              value="Registration Via Referral Link"
            >
              Registration Via Referral Link
            </Select.Option>
          </Select> */}
        </div>
        <div className="bar-chart-wrapper">
          {!PointsLoader && combinedData && combinedData?.length > 0 ? (
            <BarChart
              className="bar-recharts-wrapper"
              width={900}
              height={500}
              data={selectedEventType ? customFilterFunction() : combinedData}
            >
              <XAxis
                dataKey="name"
                interval={0}
                tick={<CustomizedAxisTick />}
              />
              <YAxis />
              <Tooltip content={<CustomTooltipBarChart />} />
              <Legend />
              {filteredAllEventTypeFilter?.map((event, index) => (
                <Bar
                  key={event}
                  dataKey={event}
                  stackId="a"
                  fill={colors[index % colors?.length]}
                />
              ))}
            </BarChart>
          ) : (
            <Empty />
          )}
        </div>
      </div>
    </div>
  );
};

export default Insights;
