import { createApi } from "@reduxjs/toolkit/dist/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";


export const vendorDashboardApi = createApi({
    reducerPath: "VendorDashboardApi",
    baseQuery:baseQueryWithReauth,
    endpoints: (builder)=>({
        getVendorApi: builder.query({
            query: () =>({
                url:"api/admin-stats/marketplace-stats/",
                method:"GET"
            })
        })
    })
})


export const { 
    useGetVendorApiQuery
} = vendorDashboardApi;