const Dashboard = () => {
  return (
    <div style={{ height: "100vh" }}>
      <iframe
        title="Your Iframe Title"
        width="100%"
        height="100%"
        src="https://tools.dkv.global/product/longevity_club_admin_dashboard_prod"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Dashboard;
