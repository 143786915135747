import React, { useEffect, useMemo } from "react";
import "./company-info.scss";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import {
  ACCOUNT_TYPES,
  getRequiredValidation,
  stringSorter,
} from "../../utils";

import {
  useGetCompanyInfoQuery,
  useUpdateCompanyInfoMutation,
} from "../../services/userApi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import countryCodes from "country-codes-list";
import ReactCountryFlag from "react-country-flag";

const { Title } = Typography;

function CompanyInformation() {
  const [form] = Form.useForm();

  const navigate = useNavigate()

  const countriesObject = countryCodes.customList(
    "countryCode",
    "{countryNameEn}"
  );

  const countries = useMemo(
    () =>
      Object.keys(countriesObject)
        .map((countryCode) => ({
          value: countriesObject[countryCode],
          label: countriesObject[countryCode],
          code: countryCode,
        }))
        .sort((a, b) => stringSorter(a.label, b.label)),
    [countriesObject]
  );


  const user = useSelector((state) => state.user.data);

  const [updateCompany, { isLoading }] = useUpdateCompanyInfoMutation();
  const {
    data,
    refetch,
    isLoading: companyLoading,
  } = useGetCompanyInfoQuery({ refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (data && !companyLoading) {
      form.setFieldsValue({
        company_name: data.company_name,
        company_website: data.company_website,
        about_company: data.about_company,
        country: data.country || undefined,

      });
    }
  }, [data, form, companyLoading]);

  const onSubmit = (values) => {
    const form = new FormData();
    form.append("company_name", values.company_name);
    form.append("company_website", values.company_website);
    form.append("about_company", values.about_company);
    form.append("country", values.country);
    if (user?.markeplace_client_code) {
      form.append("client_code", user?.markeplace_client_code);
    }
    updateCompany(form)
      .then(({ data, error }) => {
        if (error?.data?.error) {
          message.error(
            error?.data?.error || "Something went wrong, Please try again"
          );
        } else {

          message.success(
            "Company Information has been updated successfully !!"
          );
          refetch();
        }
      })
      .catch(() => {
        message.error("Something went wrong, Please try again");
      });
  };



  if (!(user?.type_of_account===ACCOUNT_TYPES.CORPORATE_CLUB_MEMBER)) {
    navigate('/my-longevity')
  }

  return (
    <div className="CompanyInformation-container">
      <div className="profile-title">Company Information</div>
      <div className="CompanyInformation-wrapper">
        <Form
          form={form}
          className="form-container"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[20]} style={{ margin: "16px" }}>
            <Col md={24} sm={24} span={24}>
              <Form.Item
                label="Company Name"
                name="company_name"
                rules={[{ ...getRequiredValidation("Company Name") }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={24} sm={24} span={24}>
              <Form.Item
                name="company_website"
                label="Company Website"
                rules={[
                  {
                    required: true,
                    message: "Company Website is required",
                  },
                  {
                    type: "url",
                    warningOnly: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={24} sm={24} span={24}>
              <Form.Item
                label="About The Company"
                name="about_company"
                rules={[
                  {
                    ...getRequiredValidation("Information About Company"),
                  },
                ]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>

            <Col md={24} sm={24} span={24} className="mb-15">
              <Form.Item
                label="Country"
                name="country"
                className="form-item"
                rules={[
                  {
                    ...getRequiredValidation("Country of Residence"),
                  },
                ]}
              >
                <Select
                  placeholder="Please Select Country of Residence"
                  allowClear
                  showSearch
                  size="large"
                >
                  {countries
                    .filter(({ code }) => !["AN"].includes(code))
                    .map(({ value, label, code }) => (
                      <Select.Option value={value}>
                        <ReactCountryFlag
                          countryCode={code}
                          style={{
                            fontSize: "14px",
                          }}
                          aria-label={label}
                        />{" "}
                        {label}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="signin-btn"
                className="form-item profile-btn-container"
              >
                <Button type="secondary">Cancel</Button>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Update Information
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default CompanyInformation;
