import React, { useEffect, useState } from 'react'
import BaseLayout from '../BaseLayout'
import { useGetHealthChartQuery } from '../../../services/healthApi'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Radio } from 'antd';
import './index.scss'
import { ArrowDownOutlined, ArrowLeftOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Bar, BarChart, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import useHealthDataInsights from './useHealthDataInsights';

const InsightChartPage = () => {
  // const Tags = {
  //   "last_one_day": 'Day',
  //   "last_one_week": 'Week',
  //   "last_one_month": 'Month',
  //   "last_three_months": "3 Months",
  //   "last_six_months": "6 Months",
  //   "last_one_year": "Year"
  // }
  const Tags = {
    "last_one_day": 'Day',
    "last_one_week": 'Day',
    "last_one_month": 'Day',
    "last_three_months": "Day",
    "last_six_months": "Day",
    "last_one_year": "Day"
}

  const sleepTimeTag = {
    "last_one_day": 'Daily',
    "last_one_week": 'Daily',
    "last_one_month": 'Daily',
    "last_three_months": "Daily",
    "last_six_months": "Daily",
    "last_one_year": "Daily"
}
  // const sleepTimeTag = {
  //   "last_one_day": 'Daily',
  //   "last_one_week": 'Weekly',
  //   "last_one_month": 'Monthly',
  //   "last_three_months": "last 3 Months",
  //   "last_six_months": "last 6 Months",
  //   "last_one_year": "Yearly"
  // }

  const categoryTag = {
    "steps": 'steps',
    "distance": "km",
    "total_duration_in_bed_seconds": "sleep Time",
    "total_burned_calories": "Cal",
    "activity_seconds": "minutes"
  }

  const AverageValues = {
    "steps": 'avg_steps',
    "distance": "avg_distance_meters",
    "total_duration_in_bed_seconds": "avg_duration_in_bed_seconds",
    "total_burned_calories": "avg_burned_calories",
    "activity_seconds": "avg_activity_seconds"
  }
  const user = useSelector((state) => state.user.data);
  const { title, specification, duration } = useParams()
  const { calculateSpecification } = useHealthDataInsights()
  const actualUserClientCode = user?.health_client_code;

  const [selectedChartOption, setSelectedChartOption] = useState(duration);
  const [selectedTag, setSelectedTag] = useState(Tags[duration])
  const [selectedSleepTag, setSelectedSleepTag] = useState(sleepTimeTag[duration])

  const [tagsForCategory, setTagsForCategory] = useState()
  const navigate = useNavigate()

  const { data: chartData } = useGetHealthChartQuery({ selectedChartOption, actualUserClientCode })

  const onChange = (e) => {
    setSelectedChartOption(e.target.value);
    setSelectedTag(Tags[e.target.value]);
    setSelectedSleepTag(sleepTimeTag[e.target.value])

  };


  useEffect(() => {
    setTagsForCategory(categoryTag[specification])
  }, [title, specification])



  const barChartData = []

  chartData && Object.keys(chartData?.data).forEach(key => {
      const keyData = chartData?.data
      let element_data = keyData[key];
      let data = {};
      data["date"] = key
      // data[title] = element_data[AverageValues[specification]]
      data[title] = calculateSpecification(element_data[AverageValues[specification]], specification)
      barChartData.push(data)
  })

  
  const formatTimeInBed = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return (
      <>
        {
          seconds ?
            <span>{hours}<span style={{ fontSize: '16px' }}>hr</span> {minutes}<span style={{ fontSize: '16px' }}>min</span></span>
            :
            <span>0<span style={{ fontSize: '16px' }}>hr</span> 0<span style={{ fontSize: '16px' }}>min</span></span>
        }
      </>
    )
  };


  return (
    <BaseLayout path="/health/insights">
      <div className="health-insight-wrapper"> 

        <ArrowLeftOutlined style={{ color: '#0064A9' }} onClick={() => navigate('/Health/insights')} />
        <br /><br />

        <div className="health-insight-title">
          {title}
        </div>

        <Radio.Group className="insight-period-buttons" onChange={onChange} defaultValue={duration}>
          <Radio.Button style={{ flexGrow: '1', textAlign: 'center' }} value="last_one_day">D</Radio.Button>
          <Radio.Button style={{ flexGrow: '1', textAlign: 'center' }} value="last_one_week">W</Radio.Button>
          <Radio.Button style={{ flexGrow: '1', textAlign: 'center' }} value="last_one_month">M</Radio.Button>
          <Radio.Button style={{ flexGrow: '1', textAlign: 'center' }} value="last_three_months">3M</Radio.Button>
          <Radio.Button style={{ flexGrow: '1', textAlign: 'center' }} value="last_six_months">6M</Radio.Button>
          <Radio.Button style={{ flexGrow: '1', textAlign: 'center' }} value="last_one_year">Y</Radio.Button>
        </Radio.Group>

        <div className="average-card-wrapper">
          <div className="insight-card-title" style={{fontSize: '24px'}}>
            Average
          </div>

          <div>

            {
              specification === "total_duration_in_bed_seconds" && <div className="insight-card-quantity">{formatTimeInBed(parseInt(chartData?.summary[AverageValues[specification]])) || 0}</div>
            }

            {
              specification === "activity_seconds" && <div className="insight-card-quantity">{(chartData?.summary[AverageValues[specification]] / 60)?.toLocaleString() || 0}</div>
            }

            {
              specification === "total_burned_calories" && <div className="insight-card-quantity">{Math.floor((chartData?.summary[AverageValues[specification]] * 1000 )).toLocaleString() || 0}</div>
            }

            {
              specification === "distance" && <div className="insight-card-quantity">{(chartData?.summary[AverageValues[specification]] / 1000 )?.toLocaleString() || 0}</div>
            }

            {
              specification === "steps" && <div className="insight-card-quantity">{parseInt(chartData?.summary[AverageValues[specification]]) || 0}</div>
            }

            {
              specification === "total_duration_in_bed_seconds" ?
                  <div className="insight-card-quantity-label">{selectedSleepTag} {tagsForCategory}</div>
                :
                <div className="insight-card-quantity-label">{tagsForCategory}/{selectedTag}</div>
            }
            <br /><br />

            <div>
  {/* {
    chartData?.summary[AverageValues[specification]] >= chartData?.changes[AverageValues[specification]] ?
      <div style={{ color: 'green', textAlign: 'center' }}>+ &nbsp;
        {
          specification === "total_duration_in_bed_seconds" && <span>{((chartData?.summary[AverageValues[specification]] - (chartData?.changes[AverageValues[specification]] || 0)) / 60).toFixed(2) || 0} minutes</span>
        }

        {
          specification === "activity_seconds" && <span>{((chartData?.summary[AverageValues[specification]] - (chartData?.changes[AverageValues[specification]] || 0)) / 60).toFixed(2) || 0}</span>
        }

        {
          specification === "total_burned_calories" && <span>{((chartData?.summary[AverageValues[specification]] - (chartData?.changes[AverageValues[specification]] || 0)) / 1000).toFixed(2)?.toLocaleString() || 0}</span>
        }

        {
          specification === "distance" && <span>{((chartData?.summary[AverageValues[specification]] - (chartData?.changes[AverageValues[specification]] || 0)) / 1000).toFixed(2)?.toLocaleString() || 0}</span>
        }

        {
          specification === "steps" && <span>{parseInt(chartData?.summary[AverageValues[specification]] - (chartData?.changes[AverageValues[specification]] || 0)) || 0}</span>
        } &nbsp; {tagsForCategory}

        <ArrowUpOutlined /></div>
    :
      <div style={{ color: 'red', textAlign: 'center' }}>- &nbsp;

        {
          specification === "total_duration_in_bed_seconds" && <span>{((chartData?.summary[AverageValues[specification]] - (chartData?.changes[AverageValues[specification]] || 0)) / 60).toFixed(2) || 0} minutes</span>
        }

        {
          specification === "activity_seconds" && <span>{((chartData?.summary[AverageValues[specification]] - (chartData?.changes[AverageValues[specification]] || 0)) / 60).toFixed(2) || 0}</span>
        }

        {
          specification === "total_burned_calories" && <span>{((chartData?.summary[AverageValues[specification]] - (chartData?.changes[AverageValues[specification]] || 0)) / 1000).toFixed(2)?.toLocaleString() || 0}</span>
        }

        {
          specification === "distance" && <span>{((chartData?.summary[AverageValues[specification]] - (chartData?.changes[AverageValues[specification]] || 0)) / 1000).toFixed(2)?.toLocaleString() || 0}</span>
        }

        {
          specification === "steps" && <span>{parseInt(chartData?.summary[AverageValues[specification]] - (chartData?.changes[AverageValues[specification]] || 0)) || 0}</span>
        } &nbsp;

      {tagsForCategory} <ArrowDownOutlined /></div>
  } */}
  {/* <div className='compare-average'>Compared to Previous {selectedTag}</div> */}
</div>

          </div>

        </div>

        <br /><br />

        <div className='average-card-chart-wrapper'>
          <ResponsiveContainer width="100%" height={350}>
            <BarChart data={barChartData?.toReversed()} {...{
              overflow: 'visible',
            }} >
              <XAxis dataKey="date"  interval={ selectedChartOption === "last_three_months" ? 2 : 0} angle={-45} textAnchor='end' />
              <YAxis />
              {/* <Tooltip /> */}
              <Legend layout="horizontal" verticalAlign="top" align="center" />
              <Bar dataKey={title} name={title} fill="#8ad6ef" />
              <ReferenceLine y={calculateSpecification(chartData?.summary[AverageValues[specification]], specification)} stroke="red" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <br /><br />

    </BaseLayout>
  )
}

export default InsightChartPage