import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";

export const ambQuestionnaireApi = createApi({
  reducerPath: "ambQuestionnaireApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAmbQuestionnaire: builder.query({
      query: () => `api/amb-question/`,
    }),
    getAmbCreateAnswer: builder.query({
      query: () => `api/amb-answer/`,
    }),
    createAmbQuestionnaireAnswer: builder.mutation({
      query: (payload) => ({
        url: `api/amb-answer/`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
    useGetAmbQuestionnaireQuery,
    useGetAmbCreateAnswerQuery,
    useCreateAmbQuestionnaireAnswerMutation,
} = ambQuestionnaireApi;
