

const useHealthDataInsights = () => {
    function calculateSpecification(value, specification) {
        if (specification === "total_duration_in_bed_seconds") {
            return (value  / 3600).toFixed(2) || 0;
        }

        if (specification === "activity_seconds") {
            return (value / 60) || 0;
        }

        if (specification === "total_burned_calories") {
            return (value * 1000) || 0;
        }

        if (specification === "distance") {
            return (value / 1000).toFixed(2)?.toLocaleString() || 0;
        }

        if (specification === "steps") {
            return parseInt(value) || 0;
        }

        return null;
    }
    function formatMonth(dateString, duration) {
        // Create a new Date object from the date string
        const date = new Date(dateString);
        
        // Get the month from the Date object (0-based index)
        const monthIndex = date.getMonth();
        
        // Define an array with month names
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        
        // Get the month name from the array
        const monthName = monthNames[monthIndex];
        
        // Get the month as a number (1-based index)
        const monthNumber = monthIndex + 1;
        
        // Return the formatted string
        return `${monthNumber} ${monthName}`;
      }
      
      

    return {
        calculateSpecification,
        formatMonth
    }
}

export default useHealthDataInsights;