import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../utils";
import baseQueryWithReauth from "./baseQueryWithReauth";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
  }),
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (body) => ({
        url: `api/register/`,
        method: "POST",
        body,
      }),
    }),
    registerWithReferral: builder.mutation({
      query: ({ referral_code, ...body }) => ({
        url: `api/register/${referral_code}`,
        method: "POST",
        body,
      }),
    }),
    login: builder.mutation({
      query: (body) => ({
        url: `api/login/`,
        method: "POST",
        body,
      }),
    }),
    verifyToken: builder.mutation({
      query: (body) => ({
        url: `api/login`,
        method: "POST",
        body,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: `api/forgot-password/`,
        method: "POST",
        body,
      }),
    }),
    socialLogin: builder.mutation({
      query: (body) => ({
        url: `api/social-login/`,
        method: "POST",
        body,
      }),
    }),
    validateCode: builder.mutation({
      query: (body) => ({
        url: `api/validate-code/`,
        method: "POST",
        body,
      }),
    }),
    resendCode: builder.mutation({
      query: (body) => ({
        url: `api/resend-code/`,
        method: "POST",
        body,
      }),
    }),
    checkEmail: builder.query({
      query: (email) => ({
        url: `api/check-email/${email}`,
        method: "GET",
      }),
    }),
    loginWithLongevityCard: builder.mutation({
      query: (body) => ({
        url: `api/login-with-lcc/`,
        method: "POST",
        body,
      }),
    }),
    getVendorInfo: builder.query({
      query: () => ({
        url: `api/m/vendors`,
        method: "GET",
      }),
    }),
    retrieveVendorByID: builder.query({
      query: (vendorID) => ({
        url: `api/m/vendors/${vendorID}`,
        method: "GET",
      }),
    }),
    updateVendorOffers: builder.mutation({
      query: (body) => ({
        url: `api/m/offers`,
        body,
        method: "POST",
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
      }),
    }),
  }),
});

export const protectedUserApi = createApi({
  reducerPath: "protectedUserApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    updateProfile: builder.mutation({
      query: (body) => ({
        url: `api/profile/`,
        body,
        method: "PATCH",
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
      }),
    }),
    changePassword: builder.mutation({
      query: (body) => ({
        url: `api/change-password/`,
        method: "POST",
        body,
      }),
    }),
    addCompanyInfo: builder.mutation({
      query: (body) => ({
        url: `api/company-info/`,
        body,
        method: "POST",
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
      }),
    }),
    addVendorInfo: builder.mutation({
      query: (body) => ({
        url: `api/m/vendors`,
        body,
        method: "POST",
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
      }),
      invalidatesTags:['status']
    }),
    getCompanyInfo: builder.query({
      query: () => ({
        url: `api/company-info/`,
        method: "GET",
      }),
    }),
    updateCompanyInfo: builder.mutation({
      query: (body) => ({
        url: `api/company-info/`,
        body,
        method: "PUT",
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
      }),
    }),
    updateVendorInfo: builder.mutation({
      query: (data) => ({
        url: `api/m/vendors`,
        body:data.form,
        params:data.params,
        method: "PUT",
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
      }),
      invalidatesTags:['status']
    }),
    getProfile: builder.query({
      query: () => ({
        url: `api/profile/`,
        method: "GET",
      }),
    }),

    accountType: builder.mutation({
      query: (body) => ({
        url: `api/account-type/`,
        method: "POST",
        body,
      }),
      invalidatesTags:['status']
    }),
    accountTypePermission: builder.query({
      query: () => ({
        url: `api/account-type-permission/`,
        method: "GET",
      }),
    }),
    updateAccountTypePermission: builder.mutation({
      query: (body) => ({
        url: `api/account-type-permission/`,
        method: "POST",
        body,
      }),
    }),
    socialMediaInformation: builder.mutation({
      query: (body) => ({
        url: `api/social-media-info/`,
        method: "POST",
        body,
      }),
    }),
    referralLink: builder.query({
      query: ({ startDate, endDate }) => ({
        url: `api/referral-link/?start_date=${startDate}&end_date=${endDate}`,
        method: "GET",
      }),
    }),
    ambassadorsList: builder.query({
      query: ({ startDate, endDate }) => ({
        url: `api/ambassadors-list/?start_date=${startDate}&end_date=${endDate}`,
        method: "GET",
      }),
    }),
    logoutAndBlockToken: builder.mutation({
    query: (body) => ({
      url: `api/logout/`,
      method: "POST"
    }),
    }),
    invalidatesTags:['status']
  }),
});

export const {
  useRegisterMutation,
  useLoginMutation,
  useVerifyTokenMutation,
  useUpdateVendorOffersMutation,
  useForgotPasswordMutation,
  useSocialLoginMutation,
  useValidateCodeMutation,
  useResendCodeMutation,
  useCheckEmailQuery,
  useLoginWithLongevityCardMutation,
  useRegisterWithReferralMutation,
  useGetVendorInfoQuery,
  useRetrieveVendorByIDQuery
} = userApi;

export const {
  useUpdateProfileMutation,
  useGetProfileQuery,
  useAccountTypeMutation,
  useAccountTypePermissionQuery,
  useUpdateAccountTypePermissionMutation,
  useSocialMediaInformationMutation,
  useReferralLinkQuery,
  useAmbassadorsListQuery,
  useAddCompanyInfoMutation,
  useUpdateCompanyInfoMutation,
  useGetCompanyInfoQuery,
  useAddVendorInfoMutation,
  useUpdateVendorInfoMutation,
  useLogoutAndBlockTokenMutation,
  useChangePasswordMutation
} = protectedUserApi;
