import { useGetLoyaltyMembersQuery } from "../../services/qibeeRewardsApi";
import Loader from "../Common/Loader";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { Card, Button, Avatar } from "antd";
import { truncateToSixDigits } from "../digits";
import insuranceData, {dummyObj} from "./dummyInsurance";

const { Meta } = Card;

const Insurance = ({ setActiveTab }) => {
    const navigate = useNavigate();
    const { data: data, isLoading } = useGetLoyaltyMembersQuery();

    if (isLoading) {
        return <Loader />;
    }

    const handleWebsiteLink = (url) => {
        window.open(url, "_blank");
    };

    const handleExchangeClick = (item) => {
        navigate("/exchange", {
            state: {
                item,
            },
        });
    };
    // to_token.brand-name- / item.to_token.symbol /  to_token.onramp / from_token.offramp// id
    return (
        <div className="card-container">
            {insuranceData.insurance.map((item) => {
                // const brand = item.to_token.brand;
                const textBelowName = `${item.description.slice(0, 62)}...`;
                const exchangeRate = `1LCP=0.025 Punti`;
                return (
                    <Card key={item.name} className="card" size="small">
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: "15px",
                            }}
                        >
                            <Avatar size={170} src={item.logo_url} />
                        </div>
                        <div className="card-body">
                            <Meta title={item.name} description={textBelowName} />
                            <p style={{ marginBottom: "0", color: "#8C8C8C" }}>
                                Exchange Rate
                            </p>
                            <p style={{ marginTop: "0", color: "#8C8C8C" }}>{exchangeRate}</p>
                            <div className="button-container">
                                <Button
                                    style={{ width: "auto" }}
                                    type="primary"
                                    onClick={() => handleExchangeClick(dummyObj)}
                                >
                                    Exchange
                                </Button>
                                <Button
                                    style={{ width: "auto" }}
                                    type="secondary"
                                    onClick={() =>
                                        handleWebsiteLink("https://platform.dkv.global/mind-map/firms/")
                                    }
                                >
                                    Website
                                </Button>
                            </div>
                        </div>
                    </Card>
                );
            })}
        </div>
    );
};

export default Insurance;
