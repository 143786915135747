import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";


export const pathWayApi = createApi({
    reducerPath: "pathWayApi",
    baseQuery: baseQueryWithReauth,
    endpoints: (builder)=>({
        // QUERIES
        getPathWays:builder.query({
            query: () =>({
                url: "api/pathways/list-pathways/", // List pathways
                method: "GET"
            })
        }),
        getPathWayById:builder.query({
            query: (pathWayID) =>({
                url: `api/pathways/view/${pathWayID}/`, // Get Pathway details by ID
                method: "GET"
            })
        }),
        getGoalById:builder.query({
            query: (goalID) =>({
                url: `api/pathways/goal/${goalID}/`, // Fetch Goal details by ID
                method: "GET"
            })
        }),
        getUserGoals:builder.query({
            query: () =>({
                url: `api/pathways/user-goals/`, // Fetch User's Goals
                method: "GET"
            })
        }),
        getUserActionItemById:builder.query({
            query: (itemID) =>({
                url: `api/pathways/action-item/${itemID}/`, // Fetch User's based action items ( Filtered by uploaded files )
                method: "GET"
            })
        }),
        getNearByClinics:builder.query({
            query: (country) =>({
                url: `api/pathways/get-nearby-clinics/?country=${country}`, // Fetch User's Nearby Clinics
                method: "GET",
                // params:{
                //     country:country
                // }
            })
        }),
        // MUTATION
        addRemoveUserGoal:builder.mutation({
            query: (goalID) =>({
                url: `api/pathways/add-goal/${goalID}/`, // User add goal to their profile
                method: "POST"
            })
        }),
        uploadReports:builder.mutation({
            query: (data) =>({
                url: `api/pathways/upload-report/`, // User add goal to their profile
                method: "POST",
                body:data
            })
        }),
        deleteReport:builder.mutation({
            query: (reportID) =>({
                url: `api/pathways/delete-report/${reportID}/`, // User add goal to their profile
                method: "DELETE",
            })
        }),
    })
})


export const {
    useGetPathWaysQuery,
    useGetPathWayByIdQuery,
    useGetGoalByIdQuery,
    useAddRemoveUserGoalMutation,
    useGetUserGoalsQuery,
    useGetUserActionItemByIdQuery,
    useUploadReportsMutation,
    useDeleteReportMutation,
    useGetNearByClinicsQuery
} = pathWayApi;