
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";

export const MealPlanApi = createApi({
    reducerPath: "MealPlanApi",
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getMealPlan: builder.query({
            query: () => ({
                url: "api/mh/meal-plan/",
                method: "GET",
            }),
        }),

        setMealPlan: builder.mutation({
            query: (mealData) => ({
                url: "api/mh/meal-plan/",
                method: "POST",
                body: mealData
            })
        }),
        setMealPlanList: builder.mutation({
            query: (mealData) => ({
                url: "api/mh/meal-plan-list/",
                method: "POST",
                body: mealData
            })
        }),
        getMealPlanList: builder.query({
            query: (param) => ({
                url: `api/mh/meal-plan-list/?client_code=${param}`,
                method: "GET",
            })
        }),
        UpdateMealPlanList: builder.mutation({
            query: ({param, body}) => ({
                url: `api/mh/meal-plan-list/?client_code=${param}`,
                method: "POST",
                body : body
            })
        })

    }),
});

export const {
    useGetMealPlanQuery,
    useSetMealPlanMutation,
    useGetMealPlanListQuery,
    useSetMealPlanListMutation,
    useUpdateMealPlanListMutation
} = MealPlanApi;
