import { lazy, Suspense, useCallback, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Profile from "../../pages/Settings/Profile";
import { ACCOUNT_TYPES } from "../../utils";
import { GlobalLoader } from "../Common/Loader";
import CompanyInformation from "../../pages/Settings/CompanyInformation";
import AddStory from "../../pages/Health/Stories/add-story";
import StoryComment from "../../pages/Health/Stories/comment-story";
import TerraConnect from "../../pages/Settings/terra-connect";
// import VendorOffers from "../../pages/Settings/vendorOffers";
import TerraModal from "../../pages/Settings/add-new-device-modal";
import { LongevityJourney } from "../../pages/LongevityJourney";
import BarChartComponent from "../../pages/Health/Health-insights";
import InsightChartPage from "../../pages/Health/Health-insights/InsightChartPage";
import Insights from "../longevity-points/Insights";
import Redeem from "../longevity-points/Redeem";
import Dashboard from "../../pages/Approval/Dashboard";
import ExchangePointsPage from "../ExchangePoints/ExchangePointsPage";
import NftCollectionPage from "../NftCollection/NftCollectionPage";
import Exchange from "../ExchangePoints/Exchange";
import TransactionsPage from "../TransactionsPage/TransactionsPage";
import NftCollection from "../NftCollection/NftCollections";

// PATHWAYS
import Pathways from "../../pages/pathways/Pathways";

const Login = lazy(() => import("../../pages/Login/Login"));
const Marketplace = lazy(() => import("../../pages/Marketplace"));
const AppLayout = lazy(() => import("../Layout/AppLayout"));
const PrivateRoute = lazy(() => import("../Common/PrivateRoute"));
const VerifyAccount = lazy(() => import("../../pages/VerifyAccount"));
const ForgotPassword = lazy(() => import("../../pages/ForgotPassword"));
const ChangePassword = lazy(() => import("../../pages/ChangePassword/ChangePassword"));
const ResetPassword = lazy(() => import("../../pages/ResetPassword"));
const Registration = lazy(() =>
  import("../../pages/Registration/Registration")
);
const Settings = lazy(() => import("../../pages/Settings"));
const VerifyingSocialLogin = lazy(() =>
  import("../../pages/VerifyingSocialLogin")
);
const TypeOfAccount = lazy(() => import("../../pages/TypeOfAccount"));
const Approval = lazy(() => import("../../pages/Approval"));
const UserApproval = lazy(() => import("../../pages/Approval/UserApproval"));
const Analytics = lazy(() => import("../../pages/Approval/Analytics"));
const SocialMediaInformation = lazy(() =>
  import("../../pages/SocialMediaInformation")
);
const AmbassadorProgram = lazy(() => import("../../pages/AmbassadorProgram"));
const AnalyticsPlatform = lazy(() => import("../../pages/AnalyticsPlatform"));

const VendorProgram = lazy(() => import("../../pages/VendorProgram"));
const IntroMarketplace = lazy(() =>
  import("../../pages/Intro-onboarding-marketplace/index")
);
const IntroHealth = lazy(() =>
  import("../../pages/Intro-onboarding-health/index")
);
const IntroPoint = lazy(() =>
  import("../../pages/Intro-onboarding-point/index")
);

const Health = lazy(() => import("../../pages/Health"));
const Steps = lazy(() => import("../../pages/Health/Steps"));
const SleepAnalytics = lazy(() => import("../../pages/Health/SleepAnalytics/SleepAnalytics.jsx"));

const MindfulnessExercise = lazy(() =>
  import("../../pages/Health/Mindfulness-exercise")
);
const CancerPage = lazy(() => import("../../pages/Health/Cancer"));
const MindfulnessDetails = lazy(() =>
  import("../../pages/Health/Mindfulness-exercise/MindfulnessDetails")
);
const ProstateCheck = lazy(() => import("../../pages/Health/Prostate-check"));
const Stories = lazy(() => import("../../pages/Health/Stories/index"));
const NutritionAdvice = lazy(() =>
  import("../../pages/Health/Nutrition-advice")
);
const SearchRecipe = lazy(() =>
  import("../../pages/Health/Nutrition-advice/SearchRecipe")
);
const FoodAnalyze = lazy(() =>
  import("../../pages/Health/Nutrition-advice/FoodAnalyze")
);
const FoodSearch = lazy(() =>
  import("../../pages/Health/Nutrition-advice/FoodSearch")
);
const SelfCheck = lazy(() => import("../../pages/Health/Self-Check"));
const LeftSideLayout = lazy(() => import("../../pages/Health/LeftSideLayout"));
const BasicInformation = lazy(() =>
  import("../../pages//Health/BasicInformation")
);
const LifestyleQuestions = lazy(() =>
  import("../../pages/Health/LifestyleQuestions")
);
const MedicalHistory = lazy(() => import("../../pages/Health/MedicalHistory"));
const MedicationTreatments = lazy(() =>
  import("../../pages/Health/MedicationTreatments")
);
const YourResults = lazy(() => import("../../pages/Health/YourResults"));
const MealPlanner = lazy(() =>
  import("../../pages/Health/MealPlanner/mealPlanner")
);
const FitnessAdvice = lazy(() => import("../../pages/Health/Fitness-advice"));
const FitnessPlan = lazy(() =>
  import("../../pages/Health/Fitness-advice/fitness-plan")
);
const FitnessCreatePlan = lazy(() =>
  import("../../pages/Health/Fitness-advice/fitnessCreatePlan")
);
const FitnessPlanGenerated = lazy(() =>
  import("../../pages/Health/Fitness-advice/FitnessPlanGenerated")
);
const FitnessPlanDetail = lazy(() =>
  import("../../pages/Health/Fitness-advice/FitnessPlanDetail")
);
const insightChartPage = lazy(() =>
  import("../../pages/Health/Health-insights/InsightChartPage")
);

const LongevityPointTransaction = lazy(() =>
  import("../../pages/LongevityPointTransaction")
);
const PathWaysP = lazy(() =>
  import("../../pages/pathways/Pathways")
);
const PathWayGoals = lazy(() =>
  import("../../pages/pathways/goals/PathwayGoals")
);
const GoalDetailView = lazy(() =>
  import("../../pages/pathways/goals/GoalView/GoalDetails")
);
const UserGoals = lazy(() =>
  import("../../pages/pathways/UserGoals/UserGoals")
);
const UserActionItemsDetails = lazy(() =>
  import("../../pages/pathways/UserGoals/UserActionItems/UserActionItemsDetails")
);
const UserSegmentsAPI = lazy(() =>
  import("../../pages/UserSegments/Segments.jsx")
);
const PushNotifications = lazy(() =>
  import("../../pages/admin-page/notifications/PushNotifications.jsx")
);
const SubmitFeedBack = lazy(() =>
  import("../../pages/feedback/UserFeedback.jsx")
);


const MyLongevity = lazy(() => import("../../pages/MyLongevity"));

const AppRoutes = () => {
  const getPrivateRoute = useCallback(
    (children) => (
      <AppLayout>
        <PrivateRoute>{children}</PrivateRoute>
      </AppLayout>
    ),
    []
  );

  const getPublicRoute = useCallback(
    (children) => <AppLayout>{children}</AppLayout>,
    []
  );

  return (
    <Suspense fallback={<GlobalLoader />}>
      <Routes>
        <Route path={"/callback"} element={<VerifyingSocialLogin />} />
        <Route
          path="/registration/:referral_code"
          element={getPublicRoute(<Registration />)}
        />
        <Route
          path="/registration"
          element={getPublicRoute(<Registration />)}
        />
        <Route
          path="/verify-account/:email"
          element={getPublicRoute(<VerifyAccount />)}
        />
        <Route
          path="/reset-password/:uid"
          element={getPublicRoute(<ResetPassword />)}
        />
        <Route path="/login" element={getPublicRoute(<Login />)} />
        <Route
          path="/login/:referral_code"
          element={getPublicRoute(<Login />)}
        />

        <Route
          path="/forgot-password"
          element={getPublicRoute(<ForgotPassword />)}
        />
        <Route
          path="/change-password"
          element={getPrivateRoute(<ChangePassword />)}
        />
        <Route path="/settings" element={getPrivateRoute(<Settings />)}>
          <Route path="profile" element={<Profile />} />
          <Route path="profile/add-new-device" element={<TerraModal />} />
          <Route path="company-info" element={<CompanyInformation />} />
          {/* <Route path="vendor-info" element={<VendorOffers />} /> */}
        </Route>
        <Route path="/admin" element={getPrivateRoute(<Approval />)}>
          <Route
            path=""
            element={<UserApproval type={ACCOUNT_TYPES.AMBASSADOR} />}
          />
          <Route
            path="corporate-club-member"
            element={
              <UserApproval type={ACCOUNT_TYPES.CORPORATE_CLUB_MEMBER} />
            }
          />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="push-notification" element={<PushNotifications />} />
          <Route
            path="vendor"
            element={<UserApproval type={ACCOUNT_TYPES.VENDOR} />}
          />
        </Route>
        <Route
          path="/onboarding"
          element={getPrivateRoute(<TypeOfAccount />)}
        />
        <Route path="/marketplace" element={getPublicRoute(<Marketplace />)} />

        <Route
          path="/social-media-information"
          element={getPrivateRoute(<SocialMediaInformation />)}
        />
        <Route
          path="/ambassador-program"
          element={getPrivateRoute(<AmbassadorProgram />)}
        />
        <Route
          path="/analytics-platform"
          element={getPrivateRoute(<AnalyticsPlatform />)}
        />
        <Route
          path="/vendor-program"
          element={getPrivateRoute(<VendorProgram />)}
        />
        <Route
          path="/journey-map"
          element={getPrivateRoute(<LongevityJourney />)}
        />
        <Route
          path="/Intro/onboarding/marketplace"
          element={getPrivateRoute(<IntroMarketplace />)}
        />
        <Route
          path="/Intro/onboarding/health"
          element={getPrivateRoute(<IntroHealth />)}
        />
        <Route
          path="/Intro/onboarding/point"
          element={getPrivateRoute(<IntroPoint />)}
        />

        <Route path="/health/steps" element={getPrivateRoute(<Steps />)} />
        <Route path="/health/sleep-analytics" element={getPrivateRoute(<SleepAnalytics />)} />
        <Route
          path="/Health/insights"
          element={getPrivateRoute(<BarChartComponent />)}
        />
        <Route
          path="/Health/insights/detail/:title/:duration/:specification"
          element={getPrivateRoute(<InsightChartPage />)}
        />
        <Route path="/health/stories" element={getPrivateRoute(<Stories />)} />
        <Route
          path="/health/terra_connect"
          element={getPrivateRoute(<TerraConnect />)}
        />

        <Route
          path="/health/stories/edit/:id"
          element={getPrivateRoute(<AddStory />)}
        />
        <Route
          path="/health/stories/add"
          element={getPrivateRoute(<AddStory />)}
        />
        <Route
          path="/health/stories/:id"
          element={getPrivateRoute(<StoryComment />)}
        />

        <Route
          path="/health/mindfulness-exercise"
          element={getPrivateRoute(<MindfulnessExercise />)}
        />
        <Route
          path="/health/self-check"
          element={getPrivateRoute(<SelfCheck />)}
        />
        <Route
          path="/health/self-check/cancer"
          element={getPrivateRoute(<CancerPage />)}
        />
        <Route
          path="/health/self-check/prostate-check"
          element={getPrivateRoute(<ProstateCheck />)}
        />

        <Route
          path="/health/nutrition-advice"
          element={getPrivateRoute(<NutritionAdvice />)}
        />
        <Route
          path="/health/nutrition-advice/searchrecipes"
          element={getPrivateRoute(<SearchRecipe />)}
        />
        <Route
          path="/health/nutrition-advice/analyzefood"
          element={getPrivateRoute(<FoodAnalyze />)}
        />
        <Route
          path="/health/nutrition-advice/searchfood"
          element={getPrivateRoute(<FoodSearch />)}
        />
        <Route
          path="/health/nutrition-advice/mealplan"
          element={getPrivateRoute(<MealPlanner />)}
        />
        {/* <Route
          path="/health/fitness-advice"
          element={getPrivateRoute(<FitnessAdvice />)}
        /> */}
        <Route
          path="/health/fitness-advice/fitness-plan"
          element={getPrivateRoute(<FitnessPlan />)}
        />
        <Route
          path="/health/fitness-advice/generate-plan/:time/:goal/:level/:location/:muscle"
          element={getPrivateRoute(<FitnessPlanGenerated />)}
        />
        <Route
          path="/health/fitness-advice/view-plan/:planId"
          element={getPrivateRoute(<FitnessPlanDetail />)}
        />
        <Route
          path="/health/fitness-advice/fitness-create-plan"
          element={getPrivateRoute(<FitnessCreatePlan />)}
        />
        <Route
          path="/health/questionnaires"
          element={getPrivateRoute(<LeftSideLayout />)}
        />
        <Route
          path="/health/basic-information"
          element={getPrivateRoute(<BasicInformation />)}
        />
        <Route
          path="/health/lifestyle-questions"
          element={getPrivateRoute(<LifestyleQuestions />)}
        />
        <Route
          path="/health/medical-history"
          element={getPrivateRoute(<MedicalHistory />)}
        />
        <Route
          path="/health/medication-treatments"
          element={getPrivateRoute(<MedicationTreatments />)}
        />
        <Route
          path="/health/your-results"
          element={getPrivateRoute(<YourResults />)}
        />

        <Route
          path="/health/mindfulness-exercise/:id"
          element={getPrivateRoute(<MindfulnessDetails />)}
        />
        {/* <Route path="/health" element={getPrivateRoute(<Health />)} /> */}
        <Route
          path="/longevity-point-history"
          element={getPrivateRoute(<LongevityPointTransaction />)}
        />
        <Route
          path="/my-longevity"
          element={getPrivateRoute(<MyLongevity />)}
        />
        <Route
          path="/redeem/exchange-points"
          element={getPrivateRoute(<ExchangePointsPage />)}
        />
        <Route
          path="/redeem/transactions"
          element={getPrivateRoute(<TransactionsPage />)}
        />
        <Route path="/exchange" element={getPrivateRoute(<Exchange />)} />
        <Route
          path="/redeem/nft-collection"
          element={getPrivateRoute(<NftCollection />)}
        />
        <Route
          path="/pathways"
          element={getPrivateRoute(<PathWaysP />)}
        />
        <Route
          path="/pathway/:id"
          element={getPrivateRoute(<PathWayGoals />)}
        />
        <Route
          path="/goal-view/:id"
          element={getPrivateRoute(<GoalDetailView />)}
        />
        <Route
          path="/my-goals"
          element={getPrivateRoute(<UserGoals />)}
        />
        <Route
          path="/action-items/:id"
          element={getPrivateRoute(<UserActionItemsDetails />)}
        />
        <Route
          path="/segments"
          element={getPrivateRoute(<UserSegmentsAPI />)}
        />
        <Route
          path="/feedbacks"
          element={getPrivateRoute(<SubmitFeedBack />)}
        />
        {/* <Route
          path="/admin/push-notification"
          element={getPrivateRoute(<PushNotifications />)}
        /> */}
        <Route path="/insights" element={getPrivateRoute(<Insights />)} />
        <Route path="/redeem" element={getPrivateRoute(<Redeem />)} />
        <Route path="/" element={<Navigate to={"/login"} />} />
        <Route path="*" element={<Navigate to={"/registration"} />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
