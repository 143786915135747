import "./redeem.scss";
import { useState } from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Card,
  Carousel,
  Col,
  Empty,
  Image,
  Input,
  Modal,
  Row,
  Spin,
  Tag,
} from "antd";
import Loader from "../Common/Loader";
import { useNavigate } from "react-router-dom";
import coins_icon from "../../assets/svg/coins_icon.svg";
import star_icon from "../../assets/svg/star_icon.svg";
import transactions_icon from "../../assets/svg/transactions_icon.svg";
import nft_icon from "../../assets/svg/nft_icon.svg";
import { useGetQiibeeAccountNumberQuery } from "../../services/qibeeRewardsApi";

const Redeem = () => {
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState(false);
  const { data: data, isLoading } = useGetQiibeeAccountNumberQuery();
  const showLongevityAssets = false; // Please remove this when not needed - as for now we have to hide My LongevityAssets card

  if (isLoading) {
    return <Loader />;
  }

  const handleMarketplaceClick = () => {
    navigate("/marketplace");
  };

  const handleExchangePointsClick = () => {
    navigate("/redeem/exchange-points");
  };

  const handleNftCollectionClick = () => {
    navigate("/redeem/nft-collection");
  };

  const handleTransactionsClick = () => {
    navigate("/redeem/transactions");
  };


  const handleOnCopyClick = () => {
    const accountNumber = data.account_number || "No account number";

    navigator.clipboard.writeText(accountNumber)
      .then(() => {
        setIsCopied(true);
        

        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      })
      .catch((err) => {
        console.error('Unable to copy text', err);
      });
  };

  return (
    <div className="redeem-points-wrapper">
      <div>
        <div>
          <div>
            <div className="offers-name" style={{marginBottom:"1rem"}}>
              <h1 style={{color:"#1D5AA2", fontSize:"2rem"}}>
              Redeem And Exchange
              </h1>
              </div>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <Card
                id="card_discover"
                className="new-category-card"
                onClick={handleMarketplaceClick}
                style={{ flex: "1" }}
              >
                <Avatar
                  size={40}
                  shape="square"
                  style={{ filter: "invert(100%)" }}
                  src={star_icon}
                ></Avatar>
                <div className="title">{"Redeem Points in Marketplace"}</div>
              </Card>
              <div style={{ width: "10px" }}></div>
              <Card
                id="card_discover"
                className="new-category-card"
                onClick={handleExchangePointsClick}
                style={{ flex: "1" }}
              >
                <Avatar
                  size={40}
                  shape="square"
                  style={{ filter: "invert(100%)" }}
                  src={coins_icon}
                ></Avatar>
                <div className="title">{"Exchange Your Points"}</div>
              </Card>
            </div>
            {
              showLongevityAssets ? 
              <Card
              id="card_discover"
              className="new-category-card"
              onClick={handleNftCollectionClick}
            >
              <Avatar
                size={40}
                shape="square"
                style={{ filter: "invert(100%)" }}
                src={nft_icon}
              ></Avatar>
              <div className="title">{"My Longevity Assets"}</div>
            </Card> : ""
            }
            

            <Card
              id="card_discover2"
              className="new-category-card"
              onClick={handleTransactionsClick}
            >
              <Avatar size={40} shape="square" src={transactions_icon}></Avatar>
              <div className="title">{"Transactions"}</div>
            </Card>
            <Col xs={24}>
              <div id="">
                <Card id="card_discover2" className="new-category-card">
                  <div>
                    <div id="title_account">Account Number</div>
                    <div className="account_div">{data.account_number}</div>
                    <div id="subtitle_account">
                      Use the account number above if you want to connect to
                      yout account with other loyalty programs
                    </div>
                    <div className="account-num-btn">
                    <button onClick={handleOnCopyClick}>
                    {isCopied ? 'Copied!' : 'Copy Number'}
                    </button>
                    </div>
                  </div>
                </Card>
              </div>
            </Col>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Redeem;
