import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";

export const onboardingQuestionnaireApi = createApi({
  reducerPath: "onboardingQuestionnaireApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getQuestionnaire: builder.query({
      query: () => `api/onboarding-question/`,
    }),
    getCreateAnswer: builder.query({
      query: () => `api/onboarding-answer/`,
    }),
    createQuestionnaireAnswer: builder.mutation({
      query: (payload) => ({
        url: `api/onboarding-answer/`,
        method: "POST",
        body: payload,
      }),
    }),
    getUserQuestionnaire: builder.query({
      query: ({user_id}) => `api/amb-question-answer/?user_id=${user_id}`,
      method: 'GET',
    }),
  }),
});

export const {
  useGetQuestionnaireQuery,
  useGetCreateAnswerQuery,
  useCreateQuestionnaireAnswerMutation,
  useGetUserQuestionnaireQuery
} = onboardingQuestionnaireApi;
