/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';

const iframeStyle = {
  width: '100%',
  height: '100vh',  
  border: 'none',  
};

export const LongevityJourney = () => {
  return (
    <iframe
      src="https://platform.dkv.global/admin-panel/widget/doc5"
      style={iframeStyle}  
    ></iframe>
  );
};