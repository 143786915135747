import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";


export const ambassadorDashboardApi = createApi({
    reducerPath: "ambassadorDashboardApi",
    baseQuery: baseQueryWithReauth,
    endpoints: (builder)=>({
        getAmbassadorData:builder.query({
            query: ()=>({
                url: 'api/admin-stats/ambassadors-stats/', 
                method: "GET",
            })
        })
    })
})

export const {
    useGetAmbassadorDataQuery
} = ambassadorDashboardApi;