import { useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Divider,
  Card,
  Avatar,
  Typography,
  Button,
  Modal,
} from "antd";
import { useState } from "react";
import { useExchangePointsMutation } from "../../services/qibeeRewardsApi";
import { useGetProfileQuery } from "../../services/userApi";
import { truncateToSixDigits } from "../digits";

const { Paragraph, Title, Text } = Typography;

const Exchange = () => {
  const [btnStatus, setBtnStatus] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const location = useLocation();
  const { item, value } = location.state;
  const [setExchangePointsData] = useExchangePointsMutation();
  const { data: userData } = useGetProfileQuery();
  const [longevityPoints, setLongevityPoints] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [modalContent, setModalContent] = useState(
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Avatar size={150} src={item.to_token.brand.brand_config.logo_url} />
      <Title style={{ color: "#717171" }}>Exchange Your Points</Title>
      <Text style={{ color: "#717171" }}>
        You will get the following amount of {item.to_token.symbol}{" "}
        {item.to_token.brand.name} Points
      </Text>
      <Title strong style={{ margin: 0, color: "#374151" }}>
        {truncateToSixDigits(
          (item.from_token.offramp / item.to_token.onramp) * longevityPoints
        )}
      </Title>
      <Title strong style={{ margin: 0, color: "#374151" }}>
        {item.to_token.symbol}
      </Title>
    </div>
  );

  const handleLongevityPointsChange = (e) => {
    setLongevityPoints(e.target.value);
  };

  const handleAccountNumberChange = (e) => {
    setAccountNumber(e.target.value);
  };

  const handleWebsiteLink = (url) => {
    window.open(url, "_blank");
  };

  const handleExchange = () => {
    setBtnStatus(true);
    setModalContent(
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Avatar size={150} src={item.to_token.brand.brand_config.logo_url} />
        <Title style={{ color: "#717171" }}>Exchange Your Points</Title>
        <Text style={{ color: "#717171" }}>
          You will get the following amount of {item.to_token.symbol}{" "}
          {item.to_token.brand.name} Points
        </Text>
        <Title strong style={{ margin: 0, color: "#374151" }}>
          {truncateToSixDigits(
            (item.from_token.offramp / item.to_token.onramp) * longevityPoints
          )}
        </Title>
        <Title strong style={{ margin: 0, color: "#374151" }}>
          {item.to_token.symbol}
        </Title>
      </div>
    );
    form
      .validateFields()
      .then(() => {
        setIsModalVisible(true);
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleOk = () => {
    const userEmail = userData.email;
    form.validateFields().then((values) => {
      setExchangePointsData({
        points: longevityPoints,
        brandb_id: item.to_token.id,
        membership_id: accountNumber,
      })
        .then((response) => {
          if (response?.data?.success === true) {
            setBtnStatus(false);
            setModalContent(
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Avatar
                  size={64}
                  src={item.to_token.brand.brand_config.logo_url}
                />
                <Title style={{ color: "#717171" }}>In Progress!</Title>
                <Paragraph style={{ textAlign: "center", color: "#717171" }}>
                Your request is being processed. If successful, you can expect to see your {item.to_token.symbol} {" "}
                in your account within the next 2 days.
                </Paragraph>
                <Title strong style={{ margin: 0, color: "#374151" }}>
                  {truncateToSixDigits(
                    (item.from_token.offramp / item.to_token.onramp) *
                      longevityPoints
                  )}
                </Title>
                <Title strong style={{ margin: 0, color: "#374151" }}>
                  {item.to_token.symbol}
                </Title>
              </div>
            );
          } else {
            setBtnStatus(false);
            setModalContent(
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Avatar
                  size={64}
                  src={item.to_token.brand.brand_config.logo_url}
                />
                <Title>Something went wrong</Title>
                <Paragraph>
                  {
                    response?.error?.data?.error || "We coudn't complete your request this time. Please try again later."
                  }
                </Paragraph>
              </div>
            );
          }
        })
        .catch((error) => {
          console.error("Error exchanging points:", error);
        });
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  console.log(item);

  return (
    <div className="profile-container">
      <div className="profile-title">Exchange for Points</div>
      <div className="profile-wrapper" style={{ paddingBottom: "0" }}>
        <Form
          form={form}
          name="myForm"
          initialValues={{
            remember: true,
          }}
        >
          <Paragraph className="sub-title">
            Enter your {item.to_token.brand.name} account information:
          </Paragraph>
          {value === "crypto" || value === "donation" ? (
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input
                placeholder="Email"
                value={accountNumber}
                onChange={handleAccountNumberChange}
              />
            </Form.Item>
          ) : (
            <Form.Item
              name="accountNumber"
              rules={[
                {
                  required: true,
                  message: "Please input your account number!",
                },
              ]}
            >
              <Input
                placeholder="Account Number"
                value={accountNumber}
                onChange={handleAccountNumberChange}
              />
            </Form.Item>
          )}
          <Paragraph className="sub-title">
            Enter the amount of longevity points to exchange:
          </Paragraph>
          <div style={{ border: "1px solid #e8e8e8", padding: "16px" }}>
            <Paragraph>
              Exchange Rate: 1LCP ={" "}
              {truncateToSixDigits(
                item.from_token.offramp / item.to_token.onramp
              )}{" "}
              {item.to_token.symbol}
            </Paragraph>
            <Form.Item
              name="longevityPoints"
              rules={[
                {
                  required: true,
                  message: "Please input your longevity points!",
                },
              ]}
            >
              <Input
                placeholder="Longevity Points"
                value={longevityPoints}
                onChange={handleLongevityPointsChange}
              />
            </Form.Item>
            <Paragraph>min 100 LCP - max 10000 LCP</Paragraph>
            <Divider />
            <Card style={{ display: "flex" }}>
              <Avatar
                size={64}
                src={item.to_token.brand.brand_config.logo_url}
              />
              <span
                style={{
                  marginLeft: "10px",
                  fontWeight: "600",
                  fontSize: "1.2rem",
                }}
              >
                {item.to_token.symbol} {item.to_token.brand.name}
              </span>
            </Card>
            <Form.Item
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                type="primary"
                onClick={handleExchange}
                htmlType="submit"
                style={{ marginTop: "10px" }}
              >
                Exchange Points
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        <Paragraph style={{ fontSize: "1rem", margin: "0" }}>
          What can you get with {item.to_token.symbol}{" "}
          {item.to_token.brand.name} Points?
        </Paragraph>
        <Button
          style={{
            backgroundColor: "#374151",
            color: "white",
            margin: "1rem 0",
            height:"40px",
            padding:"5px 43px",
          }}
          onClick={() =>
            handleWebsiteLink(item.to_token.brand.brand_config.website_url)
          }
        >
          View Shop
        </Button>
      </div>
      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Confirm"
        destroyOnClose={true}
        footer={
          btnStatus
            ? [
                <Button key="confirm" type="primary" onClick={handleOk}>
                  Confirm
                </Button>,
              ]
            : [
                <Button key="cancel" type="primary" onClick={handleCancel}>
                  Close
                </Button>,
              ]
        }
      >
        {modalContent}
      </Modal>
    </div>
  );
};

export default Exchange;
